import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz.js";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_dataviz);
am4core.useTheme(am4themes_animated);
export class graficas {
 createSeries(chart, data,  name, y, x, color,lineadata, tipo = "normal") {
  let series = chart.series.push(new am4charts.LineSeries());
  series.dataFields.valueY = y;
  series.dataFields.valueX = x;
  series.tooltipText = "{" + x + ":" + y + "}"
  series.strokeWidth = 3;
  series.stroke = am4core.color(color);
  series.propertyFields.stroke = "lineColor";
  series.propertyFields.fill = "lineColor";
  series.data = data;
  series.name = name;
  series.showOnInit = false;
  if (data.length == 1 && lineadata ) {
   let bullet = series.bullets.push(new am4charts.CircleBullet());
   bullet.circle.strokeWidth = 2;
   bullet.circle.fill = am4core.color("#fff");
   bullet.circle.propertyFields.radius = "townSize";
  }
  if (tipo == 'area') {
   series.fillOpacity = 0.85;
   series.fill = am4core.color(color);
   //series.stacked = true;
  }

 }
 async getGraficaLines(pdf,options) {
  let div = null;
  if(pdf!= null){
   div = document.createElement('div');
   div.setAttribute("id", options.id ||"graficaOMG");
   div.style.width = "1000px";
   div.style.height = "400px";
   document.body.appendChild(div);
  }else{
   div = document.getElementById( options.id ||"graficaOMG");
   div.style.width = "1000px";
   div.style.height = "400px";
  }
  
  let chart = am4core.create(div, am4charts.XYChart);
  // Create axes
  var valueXxis = chart.xAxes.push(new am4charts.ValueAxis());
  var valueYxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueXxis.title.text =options.titleX;
  valueYxis.title.text =options.titleY;
  for(let line of options.lines){
   this.createSeries(chart, line.lineadata ?options.data: options.dataSource, line.label, line.y, line.x, line.color,line.lineadata);
  }
  chart.legend = new am4charts.Legend();
  chart.legend.position = "right";
  chart.legend.scrollable = true;
  if(pdf!= null){
   pdf.addImage(await chart.exporting.getImage("png").then(data => data), options.x, options.finalY + 10, 160, 80);
   am4core.disposeAllCharts();
   document.body.removeChild(div);
   options.finalY = options.finalY + 90;
   return { pdf, finalY:options.finalY };
  }else{
   chart.cursor = new am4charts.XYCursor();
  }
  return true;
 }
 async getGraficaArea(pdf, options) {
  let div = null;
  if(pdf!= null){
   div = document.createElement('div');
   div.setAttribute("id", options.id ||"GraficaIMCPorGestacion");
   div.style.width = "1000px";
   div.style.height = "400px";
   document.body.appendChild(div);
  }else{
   div = document.getElementById( options.id ||"GraficaIMCPorGestacion");
   div.style.width = "1000px";
   div.style.height = "400px";
  }
  
 
  let chart = am4core.create(div, am4charts.XYChart);
  // Create axes

  var valueXxis = chart.xAxes.push(new am4charts.ValueAxis());
  valueXxis.title.text =options.titleX;
  valueXxis.calculateTotals = true;
  valueXxis.min = 10;
  valueXxis.max = 42;
  valueXxis.strictMinMax = true;
  var valueYxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueYxis.title.text = options.titleY;
  valueYxis.calculateTotals = true;
  valueYxis.min = 15;
  valueYxis.max = 40;
  valueYxis.strictMinMax = true;
  // Create series
 // options.data = options.data.length > 0 ? [options.data[0]] : [];
  for(let line of options.lines){
   this.createSeries(chart, line.lineadata ?options.data: options.dataSource, line.label, line.y, line.x, line.color,line.lineadata,line.tipo);
  }

  chart.legend = new am4charts.Legend();
  chart.legend.position = "right";
  chart.legend.scrollable = true;
  if(pdf!= null){
   pdf.addImage(await chart.exporting.getImage("png").then(data => data), options.x, options.finalY + 10, 160, 80);
   am4core.disposeAllCharts();
   document.body.removeChild(div);
   options.finalY = options.finalY + 90;
   return { pdf, finalY:options.finalY };
  }else{
   chart.cursor = new am4charts.XYCursor();
  }
  return true;
 }
}