export class utils {
 constructor(ips,convenciones,listaIPS,version) {
  this.ips = ips;
  this.convenciones = convenciones;
  this.encabezado = listaIPS.find(e => e.VERSION == version).LOGO;
}
 getMaestra(id, maestra) {
  if (id != null) {
   if (maestra.length > 0) {
    var ma = maestra.filter(e => e.comaId == id);
    if (ma.length > 0) {
     var m = ma[0];
     return m.comaNombrecorto;
    }
   }
  }

 }
 datediff(date1, date2) {
  var y1 = date1.getFullYear(),
   m1 = date1.getMonth(),
   d1 = date1.getDate(),
   y2 = date2.getFullYear(),
   m2 = date2.getMonth(),
   d2 = date2.getDate();
  if (d1 < d2) {
   m1--;
   var date = new Date(y2, m2, 1, 12);
   date.setDate(0);
   d1 += date.getDate();
  }
  if (m1 < m2) {
   y1--;
   m1 += 12;
  }
  return [y1 - y2, m1 - m2, d1 - d2];
 }
 calcularEdad(fecha,hoy= null) {
  var fecha_hoy = hoy ? new Date(hoy):new Date();
  var ahora_ano = fecha_hoy.getFullYear();
  var ahora_mes = fecha_hoy.getMonth();
  var ahora_dia = fecha_hoy.getDate();
  var values = fecha.split("-");
  var dia = parseInt(values[2]);
  var mes = parseInt(values[1]);
  var ano = parseInt(values[0]);

  var curd = new Date(ahora_ano, ahora_mes, ahora_dia);
  var cald = new Date(ano, mes - 1, dia);
  var dife = this.datediff(curd, cald);
  return dife[0] + " años, " + dife[1] + " meses y " + dife[2] + " días";
 }
 getCabecera(pdf, x, finalY) {
  /* pdf.text('WAYUU TALATSHI I.P.S.I', x, finalY + 5);
   pdf.setFontSize(10);
   pdf.text('Su salud con derecho propio', x, finalY + 10);
   pdf.text('Nit. 900486723-7', x, finalY + 15);
   pdf.text('Telefono: 725 4236 | email: ipsi_wayuutalatshi@hotmail.com ', x, finalY + 20);
   pdf.text('Carrera 15 N 5 - 41 Barrio El boscan Maicao (La Guajira)', x, finalY + 25);*/
  pdf.setFontSize(10);
  let img = new Image();
  img.src = this.encabezado;
  //var imgData = 'data:image/jpeg;base64,'+ Base64.encode(require('./assets/logo-ips-talatshi.jpg'));
  pdf.addImage(img, x, finalY, 180, 20);
  return pdf;
 }
 getCabecera2(pdf, x, finalY,tipo = null) {
  /* pdf.text('WAYUU TALATSHI I.P.S.I', x, finalY + 5);
   pdf.setFontSize(10);
   pdf.text('Su salud con derecho propio', x, finalY + 10);
   pdf.text('Nit. 900486723-7', x, finalY + 15);
   pdf.text('Telefono: 725 4236 | email: ipsi_wayuutalatshi@hotmail.com ', x, finalY + 20);
   pdf.text('Carrera 15 N 5 - 41 Barrio El boscan Maicao (La Guajira)', x, finalY + 25);*/
  pdf.setFontSize(10);
  let img = new Image();
  img.src = this.encabezado;
  //var imgData = 'data:image/jpeg;base64,'+ Base64.encode(require('./assets/logo-ips-talatshi.jpg'));
  pdf.addImage(img, x + 30, finalY, tipo == 'LAB' ?450:550, 80);
  return pdf;
 }
 getMaestraName(id, maestra) {
  if (id != null) {

   if (maestra.length > 0) {
    var ma = maestra.filter(e => e.comaNombrecorto == id);
    if (ma.length > 0) {
     var m = ma[0];
     return m.comaId;
    }
   }
  }

 }

 getValorcup(codigo, porcentaje, signo, cantidad, tipotarifario, salariominimo) {
  let tarifario = codigo.filter(e => e.cotaTipoTarifario == tipotarifario)[0];
  let valorneto = 0;
  if (tarifario) {
   if (parseInt(tarifario.cotaValor) < 900) {
    let valorpleno = (parseInt(salariominimo) / 30) * (parseFloat(tarifario.cotaValor));
    if (porcentaje && signo) {
     if (signo == 'POSITIVO') {
      valorneto = valorpleno + (valorpleno * parseFloat((parseInt(porcentaje) / 100)));
     } else {

      valorneto = valorpleno - (valorpleno * parseFloat((parseInt(porcentaje) / 100)));
     }
    } else {
     if (cantidad) {
      valorneto = valorpleno * parseInt(cantidad);
     } else {
      valorneto = valorpleno;
     }

    }
   } else {
    if (cantidad) {
     return parseInt(tarifario.cotaValor) * parseInt(cantidad);
    } else {
     return parseInt(tarifario.cotaValor);
    }

   }

  } else {
   return 0;
  }


  return Math.round(valorneto);

 }
 getValues(values, valor) {
  const lista = [];
  if (values) {
   var datos = values.split('|');
   if (datos.length > 0) {
    for (let i = 0; i < datos.length; i++) {
     let d = datos[i];
     lista.push({ texts: d.split(';')[0], values: d.split(';')[1] });
    }
   } else {
    lista.push({ texts: values.split(';')[0], values: values.split(';')[1] });
   }


   return (lista.find(e => e.values == valor)) ? lista.find(e => e.values == valor).texts : '';
  }
  return '';

 }
 Unidades(num) {

  switch (num) {
   case 1: return 'UN';
   case 2: return 'DOS';
   case 3: return 'TRES';
   case 4: return 'CUATRO';
   case 5: return 'CINCO';
   case 6: return 'SEIS';
   case 7: return 'SIETE';
   case 8: return 'OCHO';
   case 9: return 'NUEVE';
  }

  return '';
 }//Unidades()

 Decenas(num) {

  let decena = Math.floor(num / 10);
  let unidad = num - (decena * 10);

  switch (decena) {
   case 1:
    switch (unidad) {
     case 0: return 'DIEZ';
     case 1: return 'ONCE';
     case 2: return 'DOCE';
     case 3: return 'TRECE';
     case 4: return 'CATORCE';
     case 5: return 'QUINCE';
     default: return 'DIECI' + this.Unidades(unidad);
    }
   case 2:
    switch (unidad) {
     case 0: return 'VEINTE';
     default: return 'VEINTI' + this.Unidades(unidad);
    }
   case 3: return this.DecenasY('TREINTA', unidad);
   case 4: return this.DecenasY('CUARENTA', unidad);
   case 5: return this.DecenasY('CINCUENTA', unidad);
   case 6: return this.DecenasY('SESENTA', unidad);
   case 7: return this.DecenasY('SETENTA', unidad);
   case 8: return this.DecenasY('OCHENTA', unidad);
   case 9: return this.DecenasY('NOVENTA', unidad);
   case 0: return this.Unidades(unidad);
  }
 }//Unidades()

 DecenasY(strSin, numUnidades) {
  if (numUnidades > 0)
   return strSin + ' Y ' + this.Unidades(numUnidades)

  return strSin;
 }//DecenasY()

 Centenas(num) {
  let centenas = Math.floor(num / 100);
  let decenas = num - (centenas * 100);

  switch (centenas) {
   case 1:
    if (decenas > 0)
     return 'CIENTO ' + this.Decenas(decenas);
    return 'CIEN';
   case 2: return 'DOSCIENTOS ' + this.Decenas(decenas);
   case 3: return 'TRESCIENTOS ' + this.Decenas(decenas);
   case 4: return 'CUATROCIENTOS ' + this.Decenas(decenas);
   case 5: return 'QUINIENTOS ' + this.Decenas(decenas);
   case 6: return 'SEISCIENTOS ' + this.Decenas(decenas);
   case 7: return 'SETECIENTOS ' + this.Decenas(decenas);
   case 8: return 'OCHOCIENTOS ' + this.Decenas(decenas);
   case 9: return 'NOVECIENTOS ' + this.Decenas(decenas);
  }

  return this.Decenas(decenas);
 }//Centenas()

 Seccion(num, divisor, strSingular, strPlural) {
  let cientos = Math.floor(num / divisor)
  let resto = num - (cientos * divisor)

  let letras = '';

  if (cientos > 0)
   if (cientos > 1)
    letras = this.Centenas(cientos) + ' ' + strPlural;
   else
    letras = strSingular;

  if (resto > 0)
   letras += '';

  return letras;
 }//Seccion()

 Miles(num) {
  let divisor = 1000;
  let cientos = Math.floor(num / divisor)
  let resto = num - (cientos * divisor)

  let strMiles = this.Seccion(num, divisor, 'UN MIL', 'MIL');
  let strCentenas = this.Centenas(resto);

  if (strMiles == '')
   return strCentenas;

  return strMiles + ' ' + strCentenas;
 }//Miles()

 Millones(num) {
  let divisor = 1000000;
  let cientos = Math.floor(num / divisor)
  let resto = num - (cientos * divisor)

  let strMillones = this.Seccion(num, divisor, 'UN MILLON', 'MILLONES');
  let strMiles = this.Miles(resto);

  if (strMillones == '')
   return strMiles;

  return strMillones + ' ' + strMiles;
 }//Millones()

 NumeroALetras(num, currency) {
  currency = currency || {};
  let data = {
   numero: num,
   enteros: Math.floor(num),
   centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
   letrasCentavos: '',
   letrasMonedaPlural: currency.plural || 'PESOS',//'PESOS', 'Dólares', 'Bolívares', 'etcs'
   letrasMonedaSingular: currency.singular || 'PESO', //'PESO', 'Dólar', 'Bolivar', 'etc'
   letrasMonedaCentavoPlural: currency.centPlural || 'CENTAVOS',
   letrasMonedaCentavoSingular: currency.centSingular || 'CENTAVO'
  };

  if (data.centavos > 0) {
   data.letrasCentavos = 'CON ' + (function () {
    if (data.centavos == 1)
     return this.Millones(data.centavos) + ' ' + data.letrasMonedaCentavoSingular;
    else
     return this.Millones(data.centavos) + ' ' + data.letrasMonedaCentavoPlural;
   })();
  }

  if (data.enteros == 0)
   return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
  if (data.enteros == 1)
   return this.Millones(data.enteros) + ' ' + data.letrasMonedaSingular + ' ' + data.letrasCentavos;
  else
   return this.Millones(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
 }

 formatNumber(num, s) {
  var separador = "."; // separador para los miles
  var sepDecimal = ','; // separador para los decimales
  var simbol = s || '';
  num += '';
  var splitStr = num.split('.');
  var splitLeft = splitStr[0];
  var splitRight = splitStr.length > 1 ? sepDecimal + splitStr[1] : '';
  var regx = /(\d+)(\d{3})/;
  while (regx.test(splitLeft)) {
   splitLeft = splitLeft.replace(regx, '$1' + separador + '$2');
  }
  return simbol + splitLeft + splitRight;
 }
}