import { utils } from './utils';
import { graficas } from './graficas';
export class documentos {
    constructor(listaIPS,convenciones,ips, version = '', tipo = '') {
        this.ips = ips;
        this.convenciones = convenciones;
        this.encabezado = listaIPS.find(e => e.VERSION == version).LOGO;
        this.version = (tipo == '') ? listaIPS.find(e => e.VERSION == version).TIPO : tipo;
        this.entidad = version;
        this.utils = new utils(ips, convenciones, listaIPS, version);
        this.graficas = new graficas();
        this.cellPaddingDefault = 0.5;
        this.fontSizeDefault  = 6;
    }
    
    cargarodontograma(pdf, dientesplaca, finalY, x) {
        let img = new Image();
        img.src = dientesplaca// canvas.toDataURL("image/png");

        pdf.addImage(img, x, finalY + 10, 180, 80);
        //document.body.removeChild(div);
        finalY = finalY + 85;
        return { pdf, finalY };
    }
    cargarplaca(pdf, dientesplaca, finalY, x) {
        let img = new Image();
        img.src = dientesplaca;
        pdf.addImage(img, x, finalY + 5, 180, 80);
        finalY = finalY + 80;
        return { pdf, finalY };
    }
    getDatosPaciente(datosPaciente,tipo = 'otros',Historia = null , secciones = null){
        let formaTime = function (hora) {
            return (parseInt(hora.split(':')[0]) - ((parseInt(hora.split(':')[0]) > 12) ? 12 : 0)) + ':' + parseInt(hora.split(':')[1]) + ((parseInt(hora.split(':')[0]) > 12) ? ' pm' : ' am');
        };
        let horaInicio = (datosPaciente.tblCitaFacturaciones[0].cifaHorainicio) ? formaTime(datosPaciente.tblCitaFacturaciones[0].cifaHorainicio) : '0';
        let horaFinal = (datosPaciente.tblCitaFacturaciones[0].cifaHorafinal) ? formaTime(datosPaciente.tblCitaFacturaciones[0].cifaHorafinal) : '0';
       let resultado = []; 
       let parseStructura = [];
       parseStructura = JSON.parse(datosPaciente.StrucDataPaciente);
       
       let filaTitulo = [];
       let filaValores = [];
       let datosHeader = [
            {
                titulo: 'NOMBRE:',  
                nombre: 'NOMBRE',  
                valor: datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1 + ' ' + datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre2 + ' ' + datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1 + ' ' + datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido2,
            },{
                titulo: 'TIPO DE IDENTIFICACIÓN:',  
                nombre:'TIPO DE IDENTIFICACIÓN',
                valor: datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto,
            },{
                titulo: 'IDENTIFICACIÓN:',  
                nombre:'IDENTIFICACIÓN',
                valor: datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion,
            },{
                titulo: 'DIRECCION:',  
                nombre:'DIRECCION',
                valor: datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeDireccion + '',
            }
            ,{
                titulo: 'TELEFONO: ',
                nombre:'TELEFONO',
                valor:datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono+'',
            },{
                titulo: 'MUNICIPIO: ',
                nombre:'MUNICIPIO',
                valor: datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.municipio.comaNombrelargo,
            },{
                titulo:  'SEXO:',
                nombre:'SEXO',
                valor:((datosPaciente.tblConfPersonaspacientes[0].sexo) ? datosPaciente.tblConfPersonaspacientes[0].sexo.comaNombrelargo : 'NO TIENE'),
            },{
                titulo: 'EDAD:',
                nombre:'EDAD',
                valor:this.utils.calcularEdad(datosPaciente.tblConfPersonaspacientes[0].coppFechanacimiento,datosPaciente.tblCitaFacturaciones[0].cifaFecha)
            },{
                titulo: 'EPS:',
                nombre:'EPS',
                valor: (datosPaciente.tblCitaFacturaciones[0].tblConfContrato) ? (datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tblConfPersonasempresas[0].coemPrincipal == 1) ? 'PARTICULAR' : datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tblConfPersonasempresas[0].coemNombre : (datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemPrincipal == 1) ? 'PARTICULAR' : datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre,
            },{
                titulo: 'FECHA DE ATENCION:',
                nombre:'FECHA DE ATENCION',
                valor: datosPaciente.tblCitaFacturaciones[0].cifaFecha,
            },{
                titulo:  'REF.:',
                nombre:'REF',
                valor:datosPaciente.tblCitaFacturaciones[0].cifaNumFactura,
            },{
                titulo:  'AUTORIZACION:',
                nombre:'AUTORIZACION',
                valor:  (datosPaciente.tblCitaFacturaciones[0].cifaNumeroAutorizacion) ? datosPaciente.tblCitaFacturaciones[0].cifaNumeroAutorizacion : ''
            },{
                titulo:  'SERVICIO:',
                nombre:'SERVICIO',
                valor:  (datosPaciente.tblConfContratosxservicios.length > 0)?datosPaciente.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre: ''
            },{
                titulo:  'MEDICO:',
                nombre:'MEDICO',
                valor: (datosPaciente.tblConfPersonasempleados.length > 0)?datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + ' ' + datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1 + ' ' + datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto + ' Nº ' + datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion :''
            },
            {
                titulo:'FECHA DE NACIMIENTO:',
                nombre:'FECHA DE NACIMIENTO',
                valor:datosPaciente.tblConfPersonaspacientes[0].coppFechanacimiento
            },{
                titulo:'PAIS:',
                nombre:'PAIS',
                valor:datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.pais.comaNombrelargo,
            },{
                titulo:'DEPARTAMENTO:',
                nombre:'DEPARTAMENTO',
                valor:datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.departamento.comaNombrelargo,
            },
            {
                titulo:'BARRIO:',
                nombre:'BARRIO',
                valor:datosPaciente.tblConfPersonaspacientes[0].coppTipoBarrio?datosPaciente.tblConfPersonaspacientes[0].coppTipoBarrio:'NO TIENE',
            },
            {
                titulo:'REGIMEN DE AFILIACION:',
                nombre:'REGIMEN DE AFILIACION',
                valor:(datosPaciente.tblCitaFacturaciones[0].tblConfContrato) ? (datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tblConfPersonasempresas[0].coemPrincipal == 1) ? 'CONTRIBUTIVO' : datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tipocontrato.comaNombrelargo.split('-')[1] : (datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemPrincipal == 1) ? 'CONTRIBUTIVO' : datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tipocontrato.comaNombrelargo.split('-')[1]
            },
            {
                titulo:'DISCAPACIDAD:',
                nombre:'DISCAPACIDAD',
                valor:datosPaciente.tblConfPersonaspacientes[0].incapacidad? datosPaciente.tblConfPersonaspacientes[0].incapacidad.comaNombrelargo: 'NO TIENE'
            },
            {
                titulo:'ETNIA:',
                nombre:'ETNIA',
                valor:datosPaciente.tblConfPersonaspacientes[0].coppTipoEtnia? datosPaciente.tblConfPersonaspacientes[0].coppTipoEtnia: 'NO TIENE'
            },
            {
                titulo:'POBLACION:',
                nombre:'POBLACION',
                valor:(datosPaciente.tblConfPersonaspacientes[0].poblacion) ? datosPaciente.tblConfPersonaspacientes[0].poblacion.comaNombrelargo : 'NO TIENE'
            },
            {
                titulo:'NIVELEDUCATIVO:',
                nombre:'NIVELEDUCATIVO',
                valor:(datosPaciente.tblConfPersonaspacientes[0].educativo) ? datosPaciente.tblConfPersonaspacientes[0].educativo.comaNombrelargo : 'NO TIENE'
            },
            {
                titulo:'OCUPACION:',
                nombre:'OCUPACION',
                valor:(datosPaciente.tblConfPersonaspacientes[0].ocupacion) ? datosPaciente.tblConfPersonaspacientes[0].ocupacion.comaNombrelargo : 'NO TIENE'
            },
            {
                titulo:'ZONA:',
                nombre:'ZONA',
                valor:(datosPaciente.tblConfPersonaspacientes[0].zona) ? datosPaciente.tblConfPersonaspacientes[0].zona.comaNombrelargo : 'NO TIENE'
            },
            {
                titulo:'ESTADO CIVIL:',
                nombre:'ESTADO CIVIL',
                valor:((datosPaciente.tblConfPersonaspacientes[0].civil) ? datosPaciente.tblConfPersonaspacientes[0].civil.comaNombrelargo : 'NO TIENE')
            },
            {
                titulo:'EPS:',
                nombre:'EPS',
                valor: (datosPaciente.tblCitaFacturaciones[0].tblConfContrato) ? (datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tblConfPersonasempresas[0].coemPrincipal == 1) ? 'PARTICULAR' : datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tblConfPersonasempresas[0].coemNombre : (datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemPrincipal == 1) ? 'PARTICULAR' : datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre
            },
            {
                titulo:'CONTRATO:',
                nombre:'CONTRATO',
                valor:(datosPaciente.tblCitaFacturaciones[0].tblConfContrato) ? datosPaciente.tblCitaFacturaciones[0].tblConfContrato.cocoNombre : datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].cocoNombre
            },
            { 
                titulo: 'DIRECCION:',
                nombre:'DIRECCION',
                valor:datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeDireccion,
            },
            { 
                titulo: 'TELEFONO:',
                nombre:'TELEFONO',
                valor:datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono
            },
            { 
                titulo: 'FECHA DE ATENCION:',
                nombre:'FECHA DE ATENCION', 
                valor:datosPaciente.tblCitaFacturaciones[0].cifaFecha
            },
            { 
                titulo: `CODIGO EPS:` ,
                nombre:'CODIGO EPS',
                valor:`${(datosPaciente.tblCitaFacturaciones[0].tblConfContrato) ? datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tblConfPersonasempresas[0].coemCodigosubsidiado : datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemCodigosubsidiado}`, 
            },
            { 
                titulo: `TELEMEDICINA:` ,
                nombre:'TELEMEDICINA',
                valor:`${(datosPaciente.tblCitaFacturaciones[0].cifaTelemedicina) ? 'SI' : 'NO'}` 
            },
            { 
                titulo: `HORA DE ATENCION:` ,
                nombre:'HORA DE ATENCION',
                valor:  `${horaInicio} - ${horaFinal}`
            },
        ];
       if (tipo == 'patologia'){
            return [
                [
                    { styles: { lineWidth: 0.1, lineColor: [0, 0, 0] }, content: 'Nombre:' + datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1 + ' ' + datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre2 + ' ' + datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1 + ' ' + datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido2 },
                    { styles: { lineWidth: 0.1, lineColor: [0, 0, 0] }, content: datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto + ' ' + datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion },

                ],
                [
                    { styles: { lineWidth: 0.1, lineColor: [0, 0, 0] }, colSpan: 2, content: 'Procedimiento: ' + Historia.find(e => e.foseId == secciones.find(e => e.campos.find(a => a.foseCodigo == 'F_DEFAULT_7')).campos.find(a => a.foseCodigo == 'F_DEFAULT_7').foseId).ciffValor },

                ],
                [
                    { styles: { lineWidth: 0.1, lineColor: [0, 0, 0] }, content: 'Estudio Anterior:' + Historia.find(e => e.foseId == secciones.find(e => e.campos.find(a => a.foseCodigo == 'F_DEFAULT_8')).campos.find(a => a.foseCodigo == 'F_DEFAULT_8').foseId).ciffValor },

                    { styles: { lineWidth: 0.1, lineColor: [0, 0, 0] }, content: 'Médico Remitente: ' + datosPaciente.tblCitaFacturaciones[0].cifaMedicoremitente },

                ],
                [
                    { styles: { lineWidth: 0.1, lineColor: [0, 0, 0] }, content: 'Sexo: ' + ((datosPaciente.tblConfPersonaspacientes[0].sexo) ? datosPaciente.tblConfPersonaspacientes[0].sexo.comaNombrelargo : 'NO TIENE') },
                    { styles: { lineWidth: 0.1, lineColor: [0, 0, 0] }, content: `Edad: ${this.utils.calcularEdad(datosPaciente.tblConfPersonaspacientes[0].coppFechanacimiento,datosPaciente.tblCitaFacturaciones[0].cifaFecha)}` },

                ],
                [
                    { styles: { lineWidth: 0.1, lineColor: [0, 0, 0] }, content: 'Fecha ingreso: ' + datosPaciente.tblCitaFacturaciones[0].cifaFecha },
                    { styles: { lineWidth: 0.1, lineColor: [0, 0, 0] }, content: 'Fecha entrega: ' + Historia.find(e => e.foseId == secciones.find(e => e.campos.find(a => a.foseCodigo == 'F_DEFAULT_11')).campos.find(a => a.foseCodigo == 'F_DEFAULT_11').foseId).ciffValor },
                ],

                [

                    { styles: { lineWidth: 0.1, lineColor: [0, 0, 0] }, colSpan: 2, content: (datosPaciente.tblCitaFacturaciones[0].tblConfContrato) ? 'Institución: ' + datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tblConfPersonasempresas[0].coemNombre : 'Institución: ' + datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre },

                ]
            ]
       }else{
        let canFilas = 1;
        parseStructura =  parseStructura.filter( e => e.estado == 1);
            for (const estructura of parseStructura) {
                if(((parseStructura.indexOf(estructura) + 1) == parseStructura.length)){
                    let actual =  datosHeader.find(e => e.nombre == estructura.nombre );
                    filaTitulo.push(actual.titulo);
                    filaValores.push({content:actual.valor /*, colSpan: (12 - filaValores.reduce((partialSum, a) => partialSum + a.colSpan, 0) )*/});
                    resultado.push(filaTitulo)
                    resultado.push(filaValores)
                    filaTitulo = []
                    filaValores = []
                }else{
                    if(!((canFilas + estructura.colSpan) <  12)){
                        let actual =  datosHeader.find(e => e.nombre == estructura.nombre );
                        filaTitulo.push(actual.titulo);
                        filaValores.push({content:actual.valor /*, colSpan: estructura.colSpan*/});
                        canFilas = 0;
                        resultado.push(filaTitulo)
                        resultado.push(filaValores)
                        filaTitulo = []
                        filaValores = []
                    }else{
                        let actual =  datosHeader.find(e => e.nombre == estructura.nombre );
                        filaTitulo.push(actual.titulo);
                        filaValores.push({content:actual.valor /*, colSpan: estructura.colSpan*/});
                        canFilas = canFilas + estructura.colSpan;
                    }
                }
            }
            return resultado;
       }
    }
    getFactura(pdf, data, cuerpo, datosPaciente, finalY, datosdian) {
        let x = 14;
        pdf = this.utils.getCabecera(pdf, 14, finalY);

        let datos = [

            [

                'EPS:',
                'CONTRATO:',
                { content: 'FECHA DE ATENCION:', },
                { content: 'AUTORIZACION:', }
            ],
            [

                (datosPaciente.tblCitaFacturaciones[0].tblConfContrato) ? (datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tblConfPersonasempresas[0].coemPrincipal == 1) ? 'PARTICULAR' : datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tblConfPersonasempresas[0].coemNombre : (datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemPrincipal == 1) ? 'PARTICULAR' : datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre,
                (datosPaciente.tblCitaFacturaciones[0].tblConfContrato) ? datosPaciente.tblCitaFacturaciones[0].tblConfContrato.cocoNombre : datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].cocoNombre,
                { content: data.cifaFecha },
                { content: (data.cifaNumeroAutorizacion) ? data.cifaNumeroAutorizacion : '' }
            ],
            [

                'NIT:',
                'DIRECCION:',
                { content: 'TELEFONO:', },
                { content: 'CIUDAD:', }
            ],
            [

                (datosPaciente.tblCitaFacturaciones[0].tblConfContrato) ? (datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tblConfPersonasempresas[0].coemPrincipal == 1) ? 'PARTICULAR' : datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tblConfPersonasempresas[0].tblConfPersonas[0].copeIdentificacion : (datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemPrincipal == 1) ? 'PARTICULAR' : datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].tblConfPersonas[0].copeIdentificacion,
                (datosPaciente.tblCitaFacturaciones[0].tblConfContrato) ? (datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tblConfPersonasempresas[0].coemPrincipal == 1) ? 'PARTICULAR' : datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tblConfPersonasempresas[0].tblConfPersonas[0].copeDireccion : (datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemPrincipal == 1) ? 'PARTICULAR' : datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].tblConfPersonas[0].copeDireccion,
                (datosPaciente.tblCitaFacturaciones[0].tblConfContrato) ? (datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tblConfPersonasempresas[0].coemPrincipal == 1) ? 'PARTICULAR' : datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tblConfPersonasempresas[0].tblConfPersonas[0].copeTelefono : (datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemPrincipal == 1) ? 'PARTICULAR' : datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].tblConfPersonas[0].copeTelefono,
                (datosPaciente.tblCitaFacturaciones[0].tblConfContrato) ? (datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tblConfPersonasempresas[0].coemPrincipal == 1) ? 'PARTICULAR' : datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tblConfPersonasempresas[0].tblConfPersonas[0].municipio.comaNombrelargo : (datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemPrincipal == 1) ? 'PARTICULAR' : datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].tblConfPersonas[0].municipio.comaNombrelargo,

            ],
            [
                {
                    content: '',
                    colSpan: 4,
                    styles: { halign: 'center', valing: 'center' },
                }
            ],
            [
                {
                    content: 'DATOS DE LA PRESTACION DEL SERVICIO',
                    colSpan: 4,
                    styles: { fontStyle: 'bold', halign: 'center', valing: 'center',lineColor: [0, 0, 0],lineWidth: 0.2 },
                }
            ],
            [
                {
                    content: 'DATOS DEL PACIENTE',
                    colSpan: 4,
                    styles: {  fontStyle: 'bold' ,lineColor: [0, 0, 0],lineWidth: 0.2},
                }
            ],
            [
                { content: (datosPaciente.tblConfContratosxservicios.length > 0) ? 'SERVICIO: ' + datosPaciente.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre : (datosPaciente.tblConfCup) ? 'Procedimiento: ' + datosPaciente.tblConfCup.cocuNombre : '', colSpan: 4 }

            ],
            [
                'NOMBRE:', 'IDENTIFICACIÓN: ', 'TIPO DE IDENTIFICACIÓN:', 'FECHA DE NACIMIENTO:',
            ],
            [

                datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1 + ' ' + datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre2 + ' ' + datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1 + ' ' + datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido2,

                datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion,

                datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto,
                datosPaciente.tblConfPersonaspacientes[0].coppFechanacimiento
            ],

            [

                'PAIS: ',
                'DEPARTAMENTO:',
                'MUNICIPIO: ',
                'BARRIO:',
            ],

            [
                datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.pais.comaNombrelargo,
                datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.departamento.comaNombrelargo,
                datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.municipio.comaNombrelargo,
                datosPaciente.tblConfPersonaspacientes[0].coppTipoBarrio,

            ],

            [
                'POBLACION:',
                'NIVELEDUCATIVO:',
                'OCUPACION: ',
                'ZONA:'
            ],

            [
                ((datosPaciente.tblConfPersonaspacientes[0].poblacion) ? datosPaciente.tblConfPersonaspacientes[0].poblacion.comaNombrelargo : 'NO TIENE'),
                ((datosPaciente.tblConfPersonaspacientes[0].educativo) ? datosPaciente.tblConfPersonaspacientes[0].educativo.comaNombrelargo : 'NO TIENE'),
                ((datosPaciente.tblConfPersonaspacientes[0].ocupacion) ? datosPaciente.tblConfPersonaspacientes[0].ocupacion.comaNombrelargo : 'NO TIENE'),
                ((datosPaciente.tblConfPersonaspacientes[0].zona) ? datosPaciente.tblConfPersonaspacientes[0].zona.comaNombrelargo : 'NO TIENE'),

            ],

            [
                'SEXO:',
                'ESTADO CIVIL:',
                { content: 'DIRECCION:', },
                { content: 'TELEFONO:', },

            ],
            [
                ((datosPaciente.tblConfPersonaspacientes[0].sexo) ? datosPaciente.tblConfPersonaspacientes[0].sexo.comaNombrelargo : 'NO TIENE'),
                ((datosPaciente.tblConfPersonaspacientes[0].civil) ? datosPaciente.tblConfPersonaspacientes[0].civil.comaNombrelargo : 'NO TIENE'),
                datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeDireccion,
                datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono,
            ],


        ]
        pdf.setFontSize(7);
        pdf.text(`Factura No. ${data.cifaNumFactura} | Fecha: ${data.cifaFecha}`, x, finalY + 34);
        pdf.autoTable({
            theme:'plain',
            styles: { cellPadding: 0.2, fontSize: 6 },
            startY: finalY + 35,
            didParseCell: (data) => {
                if (!(data.row.index % 2)) {
                    data.cell.styles.fontStyle = 'bold'
                }
            },
            head: [
                [{
                    content: 'DATOS GENERALES DEL CLIENTE',
                    colSpan: 4,
                    styles: { halign: 'center', valing: 'center',lineColor: [0, 0, 0],lineWidth: 0.2 },
                }]
            ],
            body: datos
        });
        finalY = pdf.previousAutoTable.finalY;
        if (data.tblMediSalidas.length > 0) {
            pdf.setFontSize(7);
            let head = [
                [{ content: 'DATOS DEL RECETARIO', colSpan: 4, styles: { halign: 'center', valing: 'center',lineColor: [0, 0, 0],lineWidth: 0.2 }, }],
                ['MEDICAMENTO', 'CANT. RECETADA', 'CANTIDAD ENTREGADA', 'PENDIENTE', 'VALOR']
            ];
            let tabla = [];
            var total = 0;
            var copago = 0;
            data.tblMediSalidas.forEach((salida) => {
                tabla.push(
                    [
                        salida.tblMediEntrada.tblMediMedicamento.mediNombre + ' - ' + salida.tblMediEntrada.tblMediMedicamento.mediConcentracion + ' ' + salida.tblMediEntrada.tblMediMedicamento.unidad.comaNombrecorto,
                        salida.tblCitaRecetario.cireCantidadprescrita,
                        salida.mesaCantidadentregada,
                        salida.tblCitaRecetario.cirePendiente,
                        (salida.tblMediEntrada.meenPrecioventa * salida.mesaCantidadentregada)
                    ]);
                if (salida.tblCitaRecetario.cireDetalles) {
                    tabla.push([{ content: salida.tblCitaRecetario.cireDetalles, colSpan: 4 }]);
                }
                total = total + (salida.tblMediEntrada.meenPrecioventa * salida.mesaCantidadentregada);
                copago = copago + salida.mesaCoopago;
            });
            if (((data.tblConfContrato) ? data.tblConfContrato.tipotarifario.comaNombrecorto : data.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tipotarifario.comaNombrecorto) == 'FLEXIBLE') {
                total = copago;
            }
            pdf.autoTable({ theme:'plain', styles: { cellPadding: 0.2, fontSize: 6 }, startY: finalY + 4, head: head, body: tabla });
            finalY = pdf.previousAutoTable.finalY;

            tabla = [];
            let dian = '';
            if (datosPaciente.tblCitaFacturaciones[0].tblConfContrato) {
                dian = datosdian.find(e => e.comaNombrecorto == 'OBSERVACION1').comaValor + '\n';
                dian = dian + datosdian.find(e => e.comaNombrecorto == 'OBSERVACION2').comaValor + '\n';
                dian = dian + datosdian.find(e => e.comaNombrecorto == 'OBSERVACION3').comaValor + '\n';
                dian = dian + datosdian.find(e => e.comaNombrecorto == 'RESOLUCION').comaValor + '\n';
            }

            tabla.push([{ content: (datosPaciente.tblCitaFacturaciones[0].tblConfContrato) ? dian : '', rowSpan: (((datosPaciente.tblCitaFacturaciones[0].tblConfContrato) ? datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tipocontrato.comaNombrecorto : datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tipocontrato.comaNombrecorto) == "PARTICULAR") ? 4 : 3 }, 'SUBTOTAL:', this.utils.formatNumber(total)]);
            tabla.push(['COPAGO:', this.utils.formatNumber(copago)]);
            if (((datosPaciente.tblCitaFacturaciones[0].tblConfContrato) ? datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tipocontrato.comaNombrecorto : datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tipocontrato.comaNombrecorto) == "PARTICULAR") {
                tabla.push(['DESCUENTO:', this.utils.formatNumber((total - copago))]);
                tabla.push(['TOTAL:', '0']);
                tabla.push([{ content: 'VALOR EN LETRAS: ' + this.utils.NumeroALetras((0), { plural: 'PESOS', singular: 'PESO', centPlural: 'centavos', centSingular: 'centavo' }), colSpan: 3 }]);

            } else {
                tabla.push(['TOTAL:', this.utils.formatNumber((total - copago))]);
                tabla.push([{ content: 'VALOR EN LETRAS: ' + this.utils.NumeroALetras((total - copago), { plural: 'PESOS', singular: 'PESO', centPlural: 'centavos', centSingular: 'centavo' }), colSpan: 3 }]);

            }
            pdf.autoTable({
                theme:'plain',
                styles: { cellPadding: 0.2, fontSize: 6 },
                columnStyles: {
                    0: { cellWidth: 131 },
                    1: { cellWidth: 30 },
                    2: { cellWidth: 20 },
                    // etc
                }, startY: finalY + 4, body: tabla
            });
            finalY = pdf.previousAutoTable.finalY;
            pdf.setFont(undefined, "bold");
            pdf.setDrawColor(0, 0, 0);
            // pdf.line(x, finalY + 20, 60, finalY + 20);
            //  pdf.text('FIRMA', x, finalY + 26);

        } else {
            let porcentaje = null;
            let tipoporcentaje = null;
            let tipomanual = null;
            let salariominimo = null;
            let total = 0;
            let subtotal = 0;
            if (((data.tblConfContrato) ? data.tblConfContrato.tipotarifario.comaNombrecorto : data.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tipotarifario.comaNombrecorto) != 'FLEXIBLE') {
                if (data.tblCitaCita.tblConfContratosxservicios.length > 0) {
                    if (data.tblCitaCita.tblConfContratosxservicios[0].cocsPorcentaje) {
                        porcentaje = data.tblCitaCita.tblConfContratosxservicios[0].cocsPorcentaje;
                        tipoporcentaje = data.tblCitaCita.tblConfContratosxservicios[0].tipoporcentaje.comaNombrecorto;
                        tipomanual = data.tblCitaCita.tblConfContratosxservicios[0].cocsTipoManualtarifario;
                        salariominimo = data.tblCitaCita.tblConfContratosxservicios[0].tblConfContrato.tiposalario.comaNombrecorto;
                    } else {
                        porcentaje = (data.tblConfContrato) ? data.tblConfContrato.cocoPorcentaje : data.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].cocoPorcentaje;
                        tipoporcentaje = (data.tblConfContrato) ? data.tblConfContrato.tipoporcentaje.comaNombrecorto : data.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tipoporcentaje.comaNombrecorto;
                        tipomanual = (data.tblConfContrato) ? data.tblConfContrato.cocoTipoManualtarifario : data.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].cocoTipoManualtarifario;
                        salariominimo = (data.tblConfContrato) ? data.tblConfContrato.tiposalario.comaNombrecorto : data.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tiposalario.comaNombrecorto;
                    }

                } else {
                    porcentaje = (data.tblConfContrato) ? data.tblConfContrato.cocoPorcentaje : data.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].cocoPorcentaje;
                    tipoporcentaje = (data.tblConfContrato) ? data.tblConfContrato.tipoporcentaje.comaNombrecorto : data.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tipoporcentaje.comaNombrecorto;
                    tipomanual = (data.tblConfContrato) ? data.tblConfContrato.cocoTipoManualtarifario : data.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].cocoTipoManualtarifario;
                    salariominimo = (data.tblConfContrato) ? data.tblConfContrato.tiposalario.comaNombrecorto : data.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tiposalario.comaNombrecorto;
                }



                switch (data.tblCitaCita.citaTipo) {
                    case 0:
                        total = this.utils.getValorcup(data.tblCitaCita.tblConfContratosxservicios[0].tblConfCup.tarifario,
                            porcentaje,
                            tipoporcentaje,
                            null,
                            tipomanual,
                            salariominimo) - parseInt(data.cifaCopago);
                        subtotal = this.utils.getValorcup(data.tblCitaCita.tblConfContratosxservicios[0].tblConfCup.tarifario,
                            porcentaje,
                            tipoporcentaje,
                            null,
                            tipomanual,
                            salariominimo);

                        break;
                    case 1:
                        total = 0;
                        subtotal = 0;
                        for (const laboratorio of data.tblCitaOrdenes) {
                            subtotal = subtotal + this.utils.getValorcup(laboratorio.tblConfCups[0].tarifario,
                                porcentaje,
                                tipoporcentaje,
                                (datosPaciente.tblCitaFacturaciones[0].cifaCantidad > 0) ? datosPaciente.tblCitaFacturaciones[0].cifaCantidad : null,
                                tipomanual,
                                salariominimo);
                        }
                        total = subtotal - parseInt(data.cifaCopago);
                        break;
                    case 2:
                        total = this.utils.getValorcup(data.tblCitaCita.tblConfCup.tarifario,
                            porcentaje,
                            tipoporcentaje,
                            null,
                            tipomanual,
                            salariominimo) - parseInt(data.cifaCopago);
                        subtotal = this.utils.getValorcup(data.tblCitaCita.tblConfCup.tarifario,
                            porcentaje,
                            tipoporcentaje,
                            null,
                            tipomanual,
                            salariominimo);

                        break;
                    case 3:
                        total = 0;
                        subtotal = 0;
                        for (const orden of data.tblCitaOrdenes) {
                            subtotal = subtotal + this.utils.getValorcup(orden.tblConfCups[0].tarifario,
                                porcentaje,
                                tipoporcentaje,
                                orden.ciorCantidad,
                                tipomanual,
                                salariominimo);

                        }
                        total = subtotal - parseInt(data.cifaCopago);

                        break;
                    case 4:
                        total = 0;
                        subtotal = 0;
                        for (const recetario of data.tblCitaRecetarios) {
                            for (const salida of recetario.tblMediSalidas) {
                                subtotal = subtotal + (salida.tblMediEntrada.meenPrecioventa * salida.mesaCantidadentregada);
                            }
                        }
                        total = subtotal - parseInt(data.cifaCopago);
                        break;

                }
            } else {
                total = 0;
                subtotal = data.cifaCopago;
            }
            let head = [];
            if (data.tblCitaCita.citaTipo != 4) {
                head = [
                    [{ content: 'DATOS DE LA PRESTACIÓN DEL SERVICIO', colSpan: (data.tblCitaCita.citaTipo == 3) ? 4 : (datosPaciente.tblCitaFacturaciones[0].cifaCantidad > 0) ? 5 : 3, styles: { halign: 'center', valing: 'center',lineColor: [0, 0, 0],lineWidth: 0.2 }, }],
                    (data.tblCitaCita.citaTipo == 3) ? ['CUPS', 'TIPO Y DESCRIPCION DEL SERVICIO', 'CANTIDAD', 'VALOR'] : (datosPaciente.tblCitaFacturaciones[0].cifaCantidad > 0) ? ['CUPS', 'TIPO Y DESCRIPCION DEL SERVICIO', 'CANTIDAD', 'VALOR UNIT.', 'TOTAL'] : ['CUPS', 'TIPO Y DESCRIPCION DEL SERVICIO', 'VALOR']
                ];
            } else {
                head = [
                    [{ content: 'DATOS DEl RECETARIO', colSpan: 4, styles: { halign: 'center', valing: 'center',lineColor: [0, 0, 0],lineWidth: 0.2 }, }],
                    ['MEDICAMENTO', 'CANTIDAD', 'ENTREGADOS', 'PENDIENTES']
                ];
            }

            let tabla = [];
            switch (data.tblCitaCita.citaTipo) {
                case 0:
                    tabla.push(
                        [data.tblCitaCita.tblConfContratosxservicios[0].tblConfCup.cocuCups, 'SERVICIO DE ' + data.tblCitaCita.tblConfContratosxservicios[0].tblConfCup.cocuNombre,
                        this.utils.formatNumber(subtotal)
                        ]);
                    break;
                case 1:
                    for (const laboratorio of data.tblCitaOrdenes) {
                        let valorLab = this.utils.getValorcup(laboratorio.tblConfCups[0].tarifario,
                            porcentaje,
                            tipoporcentaje,
                            (datosPaciente.tblCitaFacturaciones[0].cifaCantidad > 0) ? datosPaciente.tblCitaFacturaciones[0].cifaCantidad : null,
                            tipomanual,
                            salariominimo);
                        tabla.push(
                            (datosPaciente.tblCitaFacturaciones[0].cifaCantidad > 0) ? [laboratorio.tblConfCups[0].cocuCups,
                            `${'LABORATORIO DE ' + laboratorio.tblConfCups[0].cocuNombre}`,
                            datosPaciente.tblCitaFacturaciones[0].cifaCantidad,
                            subtotal / datosPaciente.tblCitaFacturaciones[0].cifaCantidad,
                            this.utils.formatNumber(valorLab)
                            ] : [laboratorio.tblConfCups[0].cocuCups,
                            `${'LABORATORIO DE ' + laboratorio.tblConfCups[0].cocuNombre}`,
                            this.utils.formatNumber(valorLab)
                            ]);
                    }
                    break;
                case 2:
                    tabla.push(
                        [data.tblCitaCita.tblConfCup.cocuCups,
                        `${'PROCEDIMIENTO DE ' + data.tblCitaCita.tblConfCup.cocuNombre}`,
                        this.utils.formatNumber(subtotal)
                        ]);
                    break;
                case 3:
                    for (const orden of data.tblCitaOrdenes) {
                        tabla.push(
                            [orden.tblConfCups[0].cocuCups,
                            `${'ORDEN DE SERVICIO ' + orden.tblConfCups[0].cocuNombre}`,
                            orden.ciorCantidad,
                            this.utils.formatNumber(subtotal)
                            ]);
                    }
                    break;
                case 4:
                    for (const recetario of data.tblCitaRecetarios) {
                        tabla.push([recetario.tblMediMedicamento.mediNombre + ' - ' + recetario.tblMediMedicamento.mediConcentracion + ' ' + recetario.tblMediMedicamento.unidad.comaNombrecorto, recetario.cireCantidadprescrita, recetario.cirePendiente, (recetario.cireCantidadprescrita - recetario.cirePendiente)]);

                        for (const salida of recetario.tblMediSalidas) {
                            tabla.push([{ content: `- Fecha de entrega: ${salida.mesaFecha} - Cantidad: ${salida.mesaCantidadentregada} - Copago: ${salida.mesaCoopago} - Valor: ${salida.tblMediEntrada.meenPrecioventa * salida.mesaCantidadentregada}`, styles: { fontSize: 6 }, colSpan: 4 }]);
                        }
                        if (recetario.cireDetalles) {
                            tabla.push([{ content: recetario.cireDetalles, colSpan: 4 }]);
                        }
                    }

                    break;

            }
            if (data.tblCitaCita.citaTipo != 0) {
                finalY = pdf.previousAutoTable.finalY;
                let d = this.getDiagnosticos(pdf, data.tblCitaDiagnosticos, true, datosPaciente, finalY);
                pdf = d.pdf;
                finalY = d.finalY;
            }

            pdf.autoTable({theme:'plain',styles: { cellPadding: 0.2, fontSize: 6 }, startY: finalY + 5, head: head, body: tabla });
            finalY = pdf.previousAutoTable.finalY;

            tabla = [];
            let dian = '';
            if (datosPaciente.tblCitaFacturaciones[0].tblConfContrato) {
                if (datosdian.find(e => e.comaNombrecorto == 'OBSERVACION1')) {
                    dian = datosdian.find(e => e.comaNombrecorto == 'OBSERVACION1').comaValor + '\n';
                    if (datosdian.find(e => e.comaNombrecorto == 'OBSERVACION2')) {
                        dian = dian + datosdian.find(e => e.comaNombrecorto == 'OBSERVACION2').comaValor + '\n';
                    }
                    if (datosdian.find(e => e.comaNombrecorto == 'OBSERVACION3')) {
                        dian = dian + datosdian.find(e => e.comaNombrecorto == 'OBSERVACION3').comaValor + '\n';
                    }
                    if (datosdian.find(e => e.comaNombrecorto == 'RESOLUCION')) {
                        dian = dian + datosdian.find(e => e.comaNombrecorto == 'RESOLUCION').comaValor + ' ' + datosdian.find(e => e.comaNombrecorto == 'RESOLUCIONDIAN').comaValor + '\n';
                    }
                }

            }

            tabla.push([{ content: dian, rowSpan: (((datosPaciente.tblCitaFacturaciones[0].tblConfContrato) ? datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tipocontrato.comaNombrecorto : datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tipocontrato.comaNombrecorto) == "PARTICULAR") ? 4 : 3 }, 'SUBTOTAL:', this.utils.formatNumber(subtotal)]);
            tabla.push(['COPAGO:', this.utils.formatNumber(data.cifaCopago)]);
            if (((datosPaciente.tblCitaFacturaciones[0].tblConfContrato) ? datosPaciente.tblCitaFacturaciones[0].tblConfContrato.tipocontrato.comaNombrecorto : datosPaciente.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tipocontrato.comaNombrecorto) == "PARTICULAR") {
                tabla.push(['DESCUENTO:', this.utils.formatNumber(total)]);
                tabla.push(['TOTAL:', '0']);
                tabla.push([{ content: 'VALOR EN LETRAS: ' + this.utils.NumeroALetras((0), { plural: 'PESOS', singular: 'PESO', centPlural: 'centavos', centSingular: 'centavo' }), colSpan: 3 }]);

            } else {
                tabla.push(['TOTAL:', this.utils.formatNumber(total)]);
                tabla.push([{ content: 'VALOR EN LETRAS: ' + this.utils.NumeroALetras((total), { plural: 'PESOS', singular: 'PESO', centPlural: 'centavos', centSingular: 'centavo' }), colSpan: 3 }]);

            }
            pdf.autoTable({
                theme:'plain',
                styles: { cellPadding: 0.2, fontSize: 6 },
                columnStyles: {
                    0: { cellWidth: 131 },
                    1: { cellWidth: 30 },
                    2: { cellWidth: 20 },
                    // etc
                }, startY: finalY + 4, body: tabla
            });
            finalY = pdf.previousAutoTable.finalY;
            pdf.setFont(undefined, "bold");
            pdf.setDrawColor(0, 0, 0);
            //pdf.line(x, finalY + 10, 60, finalY + 10);
            // pdf.text('FIRMA', x, finalY + 14);
            finalY = finalY + 14;

        }
        let principal = this.ips.tblConfPersonasempresas[0].tblConfPersonasrepresentantes.find(e => new Date(e.cpreFechainicio.replace(/-+/g, '/')).getTime() <= new Date(data.cifaFecha.replace(/-+/g, '/')).getTime() && new Date(e.cpreFechafinal.replace(/-+/g, '/')).getTime() >= new Date(data.cifaFecha.replace(/-+/g, '/')).getTime());

        let pageHeight = pdf.internal.pageSize.height;
        if ((pageHeight - finalY) < 60) {
            pdf.addPage();
            finalY = 0;
        }

        if (principal) {
            if (principal.cpreFirma != null && principal.cpreFirma != '') {
                try {
                    if (principal.cpreFirma.split('dist').length > 0) {
                        let img = new Image();
                        img.src = '/firmas/' + principal.tblConfPersonasnaturale.tblConfPersona.copeIdentificacion + '.png';
                        //datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma.split('dist')[1].replace(/[*+?^${}()|[\]\\]/g, '/');
                        pdf.addImage(img, x, finalY + 20, 40, 20);
                    }


                } catch (error) {
                    console.log(error);
                }
            }
            pdf.setFontSize(8);
            pdf.setFont(undefined, "bold");
            pdf.text(
                principal.tblConfPersonasnaturale.copnNombre1 + ' ' +
                principal.tblConfPersonasnaturale.copnNombre2 + ' ' +
                principal.tblConfPersonasnaturale.copnApellido1 + ' ' +
                principal.tblConfPersonasnaturale.copnApellido2 + ' '
                , x, finalY + 40);
            pdf.text('REPRENSENTANTE LEGAL - CC Nº ' + principal.tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, x, finalY + 44);
            pdf.text('IPS Tools (HIS - Hospital Information System)', x, finalY + 48);
            pdf.text('®'+new Date().getFullYear()+'Powered by www.jasoftwaresas.com', x, finalY + 52);
        }
        if (cuerpo) {
            return { pdf, finalY };
        }

        if (navigator.userAgent.indexOf('irefox') > -1) {

            pdf.output("dataurlnewwindow");
        } else {
            window.open(pdf.output('bloburl'), "_blank");
        }

    }
    getCuentaCobro(pdf, data, cuerpo, finalY, tipo) {
        let x = 14;
        pdf = this.utils.getCabecera(pdf, 14, finalY);
        if (tipo == 'EVENTO') {
            pdf.text(`${this.ips.municipio.comaNombrelargo}, ${new Date().yyyymmdd()}`, x, finalY + 25);
            pdf.setFontSize(12);
            pdf.setFont(undefined, "bold");
            pdf.text(`CUENTA DE COBRO`, x + 90, finalY + 29, 'center');
            pdf.setFontSize(9);
            // pdf.setFont("normal");
            pdf.text(`${(data[0].tblConfContrato) ? data[0].tblConfContrato.tblConfPersonasempresas[0].coemNombre : data[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre} - NTI: ${(data[0].tblConfContrato) ? data[0].tblConfContrato.tblConfPersonasempresas[0].tblConfPersonas[0].copeIdentificacion : data[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].tblConfPersonas[0].copeIdentificacion}`, x + 90, finalY + 33, 'center');

            pdf.text(`DEBE A:`, x + 90, finalY + 37, 'center');
            pdf.text(`${this.ips.tblConfPersonasempresas[0].coemNombre} - NIT: ${this.ips.copeIdentificacion}`, x + 90, finalY + 41, 'center');
            pdf.text(`PERIODO DESDE ${data[0].tblCitaCuentascobrosfacturaciones[0].tblCitaCuentascobro.ciccFechainicial} HASTA ${data[0].tblCitaCuentascobrosfacturaciones[0].tblCitaCuentascobro.ciccFechafinal}`, x + 90, finalY + 45, 'center');
            pdf.text(`POR CONCEPTO DE PRESTACION DE SERVICIOS RELACIONADOS A CONTINUACION:`, x, finalY + 49);
            let head = [
                ['FACTURA', 'FECHA', 'TIPO', 'CUPS-DESCRIPCION', 'PACIENTE', 'IDENT.', 'VALOR']
            ];
            let tabla = [];

            var total = 0;
            for (var i = 0; i < data.length; i++) {

                let porcentaje = null;
                let tipoporcentaje = null;
                let tipomanual = null;
                let salariominimo = null;
                if (data[i].tblCitaCita.tblConfContratosxservicios.length > 0) {
                    if (data[i].tblCitaCita.tblConfContratosxservicios[0].cocsPorcentaje) {
                        porcentaje = data[i].tblCitaCita.tblConfContratosxservicios[0].cocsPorcentaje;
                        tipoporcentaje = data[i].tblCitaCita.tblConfContratosxservicios[0].tipoporcentaje.comaNombrecorto;
                        tipomanual = data[i].tblCitaCita.tblConfContratosxservicios[0].cocsTipoManualtarifario;
                        salariominimo = data[i].tblCitaCita.tblConfContratosxservicios[0].tblConfContrato.tiposalario.comaNombrecorto;
                    } else {
                        porcentaje = (data[i].tblConfContrato) ? data[i].tblConfContrato.cocoPorcentaje : data[i].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].cocoPorcentaje;
                        tipoporcentaje = (data[i].tblConfContrato) ? data[i].tblConfContrato.tipoporcentaje.comaNombrecorto : data[i].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tipoporcentaje.comaNombrecorto;
                        tipomanual = (data[i].tblConfContrato) ? data[i].tblConfContrato.cocoTipoManualtarifario : data[i].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].cocoTipoManualtarifario;
                        salariominimo = (data[i].tblConfContrato) ? data[i].tblConfContrato.tiposalario.comaNombrecorto : data[i].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tiposalario.comaNombrecorto;
                    }

                } else {
                    porcentaje = (data[i].tblConfContrato) ? data[i].tblConfContrato.cocoPorcentaje : data[i].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].cocoPorcentaje;
                    tipoporcentaje = (data[i].tblConfContrato) ? data[i].tblConfContrato.tipoporcentaje.comaNombrecorto : data[i].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tipoporcentaje.comaNombrecorto;
                    tipomanual = (data[i].tblConfContrato) ? data[i].tblConfContrato.cocoTipoManualtarifario : data[i].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].cocoTipoManualtarifario;
                    salariominimo = (data[i].tblConfContrato) ? data[i].tblConfContrato.tiposalario.comaNombrecorto : data[i].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tiposalario.comaNombrecorto;

                }
                let tipo = '';
                let total2 = 0;
                let subtotal = 0;
                switch (data[i].tblCitaCita.citaTipo) {
                    case 0:
                        tipo = 'CONS.';
                        total2 = this.utils.getValorcup(data[i].tblCitaCita.tblConfContratosxservicios[0].tblConfCup.tarifario,
                            porcentaje,
                            tipoporcentaje,
                            null,
                            tipomanual,
                            salariominimo) - parseInt(data[i].cifaCopago);


                        break;
                    case 1:
                        tipo = 'LAB.';
                        total2 = 0;
                        subtotal = 0;
                        for (const laboratorio of data[i].tblCitaOrdenes) {
                            subtotal = subtotal + this.utils.getValorcup(laboratorio.tblConfCups[0].tarifario,
                                porcentaje,
                                tipoporcentaje,
                                null,
                                tipomanual,
                                salariominimo);
                        }
                        total2 = subtotal - parseInt(data[i].cifaCopago);
                        break;
                    case 2:
                        tipo = 'PROC.';
                        total2 = this.utils.getValorcup(data[i].tblCitaCita.tblConfCup.tarifario,
                            porcentaje,
                            tipoporcentaje,
                            null,
                            tipomanual,
                            salariominimo) - parseInt(data[i].cifaCopago);


                        break;
                    case 3:
                        tipo = 'ORD. DE SER.';
                        total2 = 0;
                        subtotal = 0;
                        for (const orden of data[i].tblCitaOrdenes) {
                            subtotal = subtotal + this.utils.getValorcup(orden.tblConfCups[0].tarifario,
                                porcentaje,
                                tipoporcentaje,
                                orden.ciorCantidad,
                                tipomanual,
                                salariominimo);

                        }
                        total2 = subtotal - parseInt(data[i].cifaCopago);

                        break;
                    case 4:
                        tipo = 'MEDI.';
                        total2 = 0;
                        var copago = 0;
                        data[i].tblMediSalidas.forEach((salida) => {
                            total2 = total2 + (salida.tblMediEntrada.meenPrecioventa * salida.mesaCantidadentregada);
                            copago = copago + parseInt(salida.mesaCoopago);
                        });
                        total2 = total2 - copago;
                        break;

                }
                total = total + total2;
                let cup = (data[i].tblCitaCita.citaTipo == 0) ? data[i].tblCitaCita.tblConfContratosxservicios[0].tblConfCup.cocuCups + ' - ' + data[i].tblCitaCita.tblConfContratosxservicios[0].tblConfCup.cocuNombre : (data[i].tblCitaCita.citaTipo == 2) ? data[i].tblCitaCita.tblConfCup.cocuCups + ' - ' + data[i].tblCitaCita.tblConfCup.cocuNombre : '';
                tabla.push([data[i].cifaNumFactura, data[i].cifaFecha, tipo, cup, `${data[i].tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1} ${data[i].tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1}`, data[i].tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, this.utils.formatNumber(total2)]);

                if (data[i].facturarecetario.length > 0) {

                    data[i].facturarecetario.forEach((f) => {
                        tipo = 'MEDI.';
                        total2 = 0;
                        copago = 0;
                        f.tblMediSalidas.forEach((salida) => {
                            total2 = total2 + (salida.tblMediEntrada.meenPrecioventa * salida.mesaCantidadentregada);
                            copago = copago + parseInt(salida.mesaCoopago);
                        });
                        total2 = total2 - copago;
                        total = total + total2;
                        tabla.push([f.cifaNumFactura, f.cifaFecha, tipo, `${data[i].tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1}  ${data[i].tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1}`, data[i].tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, this.utils.formatNumber(total2)]);

                    });

                }
            }
            pdf.autoTable({
                styles: { cellPadding: 0.2, fontSize: 6 }, startY: finalY + 50, head: head, showHead: 'firstPage', body: tabla, pageBreak: 'auto', rowPageBreak: 'avoid'
            });
            finalY = pdf.previousAutoTable.finalY;
            tabla = [];

            tabla.push([{ content: '', rowSpan: 1 }, 'TOTALES:', this.utils.formatNumber((total))]);
            tabla.push([{ content: 'SON: ' + this.utils.NumeroALetras((total), { plural: 'PESOS', singular: 'PESO', centPlural: 'centavos', centSingular: 'centavo' }), colSpan: 3 }]);
            pdf.autoTable({
                styles: { cellPadding: 0.2, fontSize: 6 },
                columnStyles: {
                    0: { cellWidth: 131 },
                    1: { cellWidth: 30 },
                    2: { cellWidth: 20 },
                    // etc
                }, startY: finalY + 5, body: tabla
            });
        } else {
            pdf.text(`${this.ips.municipio.comaNombrelargo}, ${new Date().yyyymmdd()}`, x + 90, finalY + 35, 'center');
            pdf.text(`FACTURA No. ${data[0].tblCitaCuentascobrosfacturaciones[0].tblCitaCuentascobro.ciccNumFactura}`, x + 90, finalY + 40, 'center');
            pdf.text(`${(data[0].tblConfContrato) ? data[0].tblConfContrato.tblConfPersonasempresas[0].coemNombre : data[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre}`, x + 90, finalY + 45, 'center');
            pdf.text(`${(data[0].tblConfContrato) ? data[0].tblConfContrato.tblConfPersonasempresas[0].tblConfPersonas[0].copeTipoIdentificacion : data[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].tblConfPersonas[0].copeTipoIdentificacion}`, x + 90, finalY + 50, 'center');

            let tabla = [];
            let head = [
                [{ content: 'DETALLES DE LA FACTURA', colSpan: 2 }, 'VALOR TOTAL']
            ];
            tabla.push([{ content: 'PERIODO FACTURADO' }, data[0].cifaFecha.substr(0, 7), '']);
            tabla.push([{ content: 'CONTRATO Nº' }, data[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].cocoNombre, '']);
            tabla.push([{ content: 'MUNICIPIO' }, this.ips.municipio.comaNombrelargo, '']);
            tabla.push([{ content: 'VIGENCIA CONTRATO' }, data[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].cocoFechainicio + ' - ' + data[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].cocoFechafinal, '']);
            tabla.push([{ content: 'MODALIDAD DEL CONTRATO' }, 'CAPITA', '']);
            tabla.push([{ content: 'NIVELES CONTRATADOS' }, data[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tipocontrato.comaNombrelargo, '']);
            tabla.push([{ content: 'NUMERO DE AFILIADOS' }, data[0].tblCitaCuentascobrosfacturaciones[0].tblCitaCuentascobro.ciccNumAfiliado, '']);
            tabla.push([{ content: 'VALOR POR AFILIADO' }, this.utils.formatNumber(data[0].tblCitaCuentascobrosfacturaciones[0].tblCitaCuentascobro.ciccValorAfiliado), '']);
            let total = parseInt(data[0].tblCitaCuentascobrosfacturaciones[0].tblCitaCuentascobro.ciccNumAfiliado) * parseFloat(data[0].tblCitaCuentascobrosfacturaciones[0].tblCitaCuentascobro.ciccValorAfiliado)
            tabla.push([{ content: 'Esta Factura se asimila en sus efectos a una Letra de Cambio (Articulo 772 y 774 del Código de Comercio) y la mora en el pago acarrea intereses de conformidad de acuerdo a lo dispuesto a la ley. ', rowSpan: 4 }, 'SUBTOTALES:', this.utils.formatNumber((total))]);
            tabla.push(['IVA:', this.utils.formatNumber(('0'))]);
            tabla.push(['DESCUENTO:', this.utils.formatNumber(('0'))]);
            tabla.push(['TOTAL:', this.utils.formatNumber((total))]);
            tabla.push([{ content: 'SON: ' + this.utils.NumeroALetras((total), { plural: 'PESOS', singular: 'PESO', centPlural: 'centavos', centSingular: 'centavo' }), colSpan: 3 }]);
            pdf.autoTable({
                styles: { cellPadding: 0.2, fontSize: 6 },
                columnStyles: {
                    0: { cellWidth: 111 },
                    1: { cellWidth: 40 },
                    2: { cellWidth: 30 },
                    // etc
                }, startY: finalY + 55, head: head, body: tabla
            });
        }

        finalY = pdf.previousAutoTable.finalY;
        let principal = this.ips.tblConfPersonasempresas[0].tblConfPersonasrepresentantes.find(e => new Date(e.cpreFechainicio.replace(/-+/g, '/')).getTime() <= new Date(data[0].cifaFecha.replace(/-+/g, '/')).getTime() && new Date(e.cpreFechafinal.replace(/-+/g, '/')).getTime() >= new Date(data[0].cifaFecha.replace(/-+/g, '/')).getTime());
        if (principal) {
            let pageHeight = pdf.internal.pageSize.height;
            if ((pageHeight - finalY) < 60) {
                pdf.addPage();
                finalY = 0;
            }
            if (principal.cpreFirma != null && principal.cpreFirma != '') {
                try {
                    if (principal.cpreFirma.split('dist').length > 0) {
                        let img = new Image();
                        img.src = '/firmas/' + principal.tblConfPersonasnaturale.tblConfPersona.copeIdentificacion + '.png';
                        //datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma.split('dist')[1].replace(/[*+?^${}()|[\]\\]/g, '/');
                        pdf.addImage(img, x, finalY + 20, 40, 20);
                    }


                } catch (error) {
                    console.log(error);
                }
            }

            pdf.setFontSize(8);
            pdf.setFont(undefined, "bold");
            pdf.text(
                principal.tblConfPersonasnaturale.copnNombre1 + ' ' +
                principal.tblConfPersonasnaturale.copnNombre2 + ' ' +
                principal.tblConfPersonasnaturale.copnApellido1 + ' ' +
                principal.tblConfPersonasnaturale.copnApellido2 + ' '
                , x, finalY + 40);
            pdf.text('REPRENSENTANTE LEGAL - CC Nº ' + principal.tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, x, finalY + 44);
            pdf.text('IPS Tools (HIS - Hospital Information System)', x, finalY + 48);
            pdf.text('®'+new Date().getFullYear()+'Powered by www.jasoftwaresas.com', x, finalY + 52);
        }

        if (cuerpo) {
            return { pdf, finalY };
        }
        if (navigator.userAgent.indexOf('irefox') > -1) {

            pdf.output("dataurlnewwindow");
        } else {
            window.open(pdf.output('bloburl'), "_blank");
        }
    }
    getReporteFactura(data) {
        let text = '';
        for (var i = 0; i < data.length; i++) {
            text = text + this.getFactura(data[i], true);

        }
        text = text + this.getCuentaCobro(data, true);
        return this.cabecera + text + this.pie;
    }
    getOrdenesLaboratorios(pdf, ordenes, cuerpo, datosPaciente, finalY, diagnosticos, email = false,porPagina = false) {
        let x = 14;
        if (ordenes.filter(e => e.orden).length > 0) {
            let confOrdenes = JSON.parse(datosPaciente.confOrdenes);
            let cellPadding = this.cellPaddingDefault
            let fontSize = this.fontSizeDefault
            if(confOrdenes.laboratorios && confOrdenes.laboratorios.estilos){
                cellPadding = confOrdenes.laboratorios.estilos.cellPadding??0.2;
                fontSize = confOrdenes.laboratorios.estilos.fontSize ??6;
            }
            if (!cuerpo || porPagina) {
                let datos = this.getDatosPaciente(datosPaciente);
                pdf = this.utils.getCabecera(pdf, 14, finalY);
                pdf.line(x, finalY + 25, 195, finalY + 25);
                pdf.setFontSize(10);
                pdf.setFont(undefined, "bold");
                pdf.text('ORDENES', x, finalY + 29);
                if(confOrdenes.laboratorios && confOrdenes.laboratorios.fechaVencimiento == 'SI'){
                    pdf.text('fecha vencimiento:'+ new Date(datosPaciente.tblCitaFacturaciones[0].cifaFecha).addDays(confOrdenes.laboratorios.diasVencimiento).yyyymmdd(), 140, finalY + 29);
                }
                
                pdf.line(x, finalY + 30, 195, finalY + 30);
                pdf.autoTable({
                    theme:'plain',
                    styles: { cellPadding: cellPadding, fontSize:fontSize, textColor: [0, 0, 0] },
                    startY: finalY + 34,
                    didParseCell: (data) => {
                        if (!(data.row.index % 2)) {
                            data.cell.styles.fontStyle = 'bold'
                        }
                        data.cell.styles.textColor = [0, 0, 0]
                    },
                    head: [
                        [{
                            content: 'DATOS DEL PACIENTE',
                            colSpan: 4,
                            styles: { halign: 'center', valing: 'center' ,lineColor: [0, 0, 0],lineWidth: 0.2},
                        }]
                    ],
                    body: datos
                });
                finalY = pdf.previousAutoTable.finalY;
                let d = this.getDiagnosticos(pdf, diagnosticos, true, datosPaciente, finalY);
                pdf = d.pdf;
                finalY = d.finalY;
            }
            let head = [
                [{ content: 'ORDENES DE LABORATORIO', colSpan: 4, styles: { halign: 'center', valing: 'center' ,lineColor: [0, 0, 0],lineWidth: 0.2}, }],
                ['COD. CUPS','CUPS', 'CANT.', 'OBSERVACION']
            ];
            let tabla = [];
            if (ordenes.filter(e => e.orden).length > 0) {
                ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_LAB').forEach((orden) => {
                    tabla.push([orden.tblConfCups[0].cocuCups,orden.tblConfCups[0].cocuNombre,orden.ciorCantidad,  orden.ciorObservacion]);
                });
            }

            // for (let i = 0; i < ordenes.length; i++) {
            //     let orden = ordenes[i];
            //     tabla.push([orden.tblConfCups[0].cocuNombre, orden.orden.comaNombrelargo, orden.ciorObservacion]);
            // }
            if (tabla.length > 0) {
                pdf.autoTable({
                    theme:'plain',
                    styles: { cellPadding: cellPadding, fontSize: fontSize, overflow: 'linebreak', textColor: [0, 0, 0] ,lineColor: [0, 0, 0],lineWidth: 0.2 },
                     startY: finalY + 5, head: head, showHead: 'firstPage', body: tabla, pageBreak: 'auto'
                });
                finalY = pdf.previousAutoTable.finalY;
            }
            if (cuerpo && !porPagina) {
                return { pdf, finalY };
            }
            pdf.setFontSize(7);
            pdf.setFont(undefined, "bold");
            let pageHeight = pdf.internal.pageSize.height;
            if ((pageHeight - finalY) < 60) {
                pdf.addPage();
                finalY = 0;
            }
            if (
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != null &&
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != ''
            ) {
                try {
                    let imgEmpleado = new Image();
                    imgEmpleado.src = datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma;
                    pdf.addImage(imgEmpleado, x, finalY + 5, 35, 20);
                } catch (error) {
                    console.log(error);
                }
            }
            

            pdf.text(
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + ' ' +
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1, x, finalY + 25);
            pdf.text(datosPaciente.tblConfPersonasempleados[0].cargo.comaNombrelargo, x, finalY + 28);
            if (datosPaciente.tblConfPersonasempleados[0].coprPieFirma) {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].coprPieFirma, x, finalY + 31);
            } else {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto + ' Nº ' +
                    datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, x, finalY + 31);
            }
            if (!porPagina) {
                if (email) {
                    return pdf.output('blob');
                } else {
                    if (navigator.userAgent.indexOf('irefox') > -1) {

                        pdf.output("dataurlnewwindow");
                    } else {
                        window.open(pdf.output('bloburl'), "_blank");
                    }
                }
            }else { return { pdf, finalY }; }
        } else { return { pdf, finalY }; }

        //FIN ORDENES------------------------------------------------------------------------------------------------------
    }
    getOrdenesImagenDiag(pdf, ordenes, cuerpo, datosPaciente, finalY, diagnosticos, email = false,porPagina = false) {
        let x = 14;
        if (ordenes.filter(e => e.orden).length > 0) {
            let confOrdenes = JSON.parse(datosPaciente.confOrdenes);
            let cellPadding = this.cellPaddingDefault
            let fontSize = this.fontSizeDefault
            if(confOrdenes.imagenesDx && confOrdenes.imagenesDx.estilos){
                cellPadding = confOrdenes.imagenesDx.estilos.cellPadding??0.2;
                fontSize = confOrdenes.imagenesDx.estilos.fontSize ??6;
            }
            if (!cuerpo || porPagina) {
                let datos = this.getDatosPaciente(datosPaciente);
                pdf = this.utils.getCabecera(pdf, 14, finalY);
                pdf.line(x, finalY + 25, 195, finalY + 25);
                pdf.setFontSize(10);
                pdf.setFont(undefined, "bold");
                pdf.text('ORDENES', x, finalY + 29);
                if(confOrdenes.imagenesDx && confOrdenes.imagenesDx.fechaVencimiento == 'SI'){
                    pdf.text('fecha vencimiento:'+ new Date(datosPaciente.tblCitaFacturaciones[0].cifaFecha).addDays(confOrdenes.imagenesDx.diasVencimiento).yyyymmdd(), 140, finalY + 29);
                }
                pdf.line(x, finalY + 30, 195, finalY + 30);
                pdf.autoTable({
                    theme:'plain',
                    styles: { cellPadding: cellPadding, fontSize: fontSize, textColor: [0, 0, 0] },
                    startY: finalY + 34,
                    didParseCell: (data) => {
                        if (!(data.row.index % 2)) {
                            data.cell.styles.fontStyle = 'bold'
                        }
                        data.cell.styles.textColor = [0, 0, 0]
                    },
                    head: [
                        [{
                            content: 'DATOS DEL PACIENTE',
                            colSpan: 4,
                            styles: { halign: 'center', valing: 'center',lineColor: [0, 0, 0],lineWidth: 0.2 },
                        }]
                    ],
                    body: datos
                });
                finalY = pdf.previousAutoTable.finalY;
                let d = this.getDiagnosticos(pdf, diagnosticos, true, datosPaciente, finalY);
                pdf = d.pdf;
                finalY = d.finalY;
            }
            let head = [
                [{ content: 'ORDENES DE IMAGEN DIAGNOSTICO', colSpan: 4, styles: { halign: 'center', valing: 'center',lineColor: [0, 0, 0],lineWidth: 0.2 }, }],
                ['COD. CUPS','CUPS', 'CANT.', 'OBSERVACION']
            ];
            let tabla = [];
            ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_IMG').forEach((orden) => {
                tabla.push([orden.tblConfCups[0].cocuCups,orden.tblConfCups[0].cocuNombre,orden.ciorCantidad,  orden.ciorObservacion]);
            });
            if (tabla.length > 0) {
                pdf.autoTable({
                    theme:'plain',
                    styles: { cellPadding:cellPadding, fontSize: fontSize, textColor: [0, 0, 0],lineColor: [0, 0, 0],lineWidth: 0.2  },
                     startY: finalY + 5, head: head, showHead: 'firstPage', body: tabla, pageBreak: 'avoid'
                });
                finalY = pdf.previousAutoTable.finalY;
            }
            if (cuerpo && !porPagina) {
                return { pdf, finalY };
            }
            pdf.setFontSize(7);
            pdf.setFont(undefined, "bold");
            let pageHeight = pdf.internal.pageSize.height;
            if ((pageHeight - finalY) < 60) {
                pdf.addPage();
                finalY = 0;
            }
            if (
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != null &&
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != ''
            ) {
                try {
                    let imgEmpleado = new Image();
                    imgEmpleado.src = datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma;
                    pdf.addImage(imgEmpleado, x, finalY + 5, 35, 20);
                } catch (error) {
                    console.log(error);
                }
            }

            pdf.text(
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + ' ' +
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1, x, finalY + 25);
            pdf.text(datosPaciente.tblConfPersonasempleados[0].cargo.comaNombrelargo, x, finalY + 28);
            if (datosPaciente.tblConfPersonasempleados[0].coprPieFirma) {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].coprPieFirma, x, finalY + 31);
            } else {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto + ' Nº ' +
                    datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, x, finalY + 31);
            }
            if (!porPagina) {
                if (email) {
                    return pdf.output('blob');
                } else {
                    if (navigator.userAgent.indexOf('irefox') > -1) {

                        pdf.output("dataurlnewwindow");
                    } else {
                        window.open(pdf.output('bloburl'), "_blank");
                    }
                }
            }else { return { pdf, finalY }; }
        } else { return { pdf, finalY }; }

        //FIN ORDENES------------------------------------------------------------------------------------------------------
    }
    getOrdenesServicios(pdf, ordenes, cuerpo, datosPaciente, finalY, diagnosticos, email = false,porPagina = false) {
        let x = 14;
        if (ordenes.filter(e => e.orden).length > 0) {
            let confOrdenes = JSON.parse(datosPaciente.confOrdenes);
            let cellPadding = this.cellPaddingDefault
            let fontSize = this.fontSizeDefault
            if(confOrdenes.servicios && confOrdenes.servicios.estilos){
                cellPadding = confOrdenes.servicios.estilos.cellPadding??0.2;
                fontSize = confOrdenes.servicios.estilos.fontSize ??6;
            }
            if (!cuerpo || porPagina) {
                let datos = this.getDatosPaciente(datosPaciente);
                pdf = this.utils.getCabecera(pdf, 14, finalY);
                pdf.line(x, finalY + 25, 195, finalY + 25);
                pdf.setFontSize(10);
                pdf.setFont(undefined, "bold");
                pdf.text('ORDENES', x, finalY + 29);
                if(confOrdenes.servicios && confOrdenes.servicios.fechaVencimiento == 'SI'){
                    pdf.text('fecha vencimiento:'+ new Date(datosPaciente.tblCitaFacturaciones[0].cifaFecha).addDays(confOrdenes.servicios.diasVencimiento).yyyymmdd(), 140, finalY + 29);
                }
                pdf.line(x, finalY + 30, 195, finalY + 30);
                pdf.autoTable({
                    theme:'plain',
                    styles: { cellPadding: cellPadding, fontSize: fontSize, textColor: [0, 0, 0] },
                    startY: finalY + 34,
                    didParseCell: (data) => {
                        if (!(data.row.index % 2)) {
                            data.cell.styles.fontStyle = 'bold'
                        }
                        data.cell.styles.textColor = [0, 0, 0]
                    },
                    head: [
                        [{
                            content: 'DATOS DEL PACIENTE',
                            colSpan: 4,
                            styles: { halign: 'center', valing: 'center' ,lineColor: [0, 0, 0],lineWidth: 0.2},
                        }]
                    ],
                    body: datos
                });
                finalY = pdf.previousAutoTable.finalY;
                let d = this.getDiagnosticos(pdf, diagnosticos, true, datosPaciente, finalY);
                pdf = d.pdf;
                finalY = d.finalY;
            }
           
           let head = [
                [{ content: 'ORDENES DE SERVICIOS', colSpan: 4, styles: { halign: 'center', valing: 'center' ,lineColor: [0, 0, 0],lineWidth: 0.2}, }],
                ['COD. CUPS','CUPS', 'CANT.', 'OBSERVACION']
            ];
           let tabla = [];
            ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_SER').forEach((orden) => {
                tabla.push([orden.tblConfCups[0].cocuCups,orden.tblConfCups[0].cocuNombre, orden.ciorCantidad, orden.ciorObservacion]);
            });
            if (tabla.length > 0) {
                pdf.autoTable({
                    theme:'plain',
                    styles: { cellPadding: cellPadding, fontSize: fontSize, textColor: [0, 0, 0],lineColor: [0, 0, 0],lineWidth: 0.2  }, 
                     startY: finalY + 5, head: head, showHead: 'firstPage', body: tabla, pageBreak: 'avoid'
                });
                finalY = pdf.previousAutoTable.finalY;
            }
            if (cuerpo && !porPagina) {
                return { pdf, finalY };
            }
            pdf.setFontSize(7);
            pdf.setFont(undefined, "bold");
            let pageHeight = pdf.internal.pageSize.height;
            if ((pageHeight - finalY) < 60) {
                pdf.addPage();
                finalY = 0;
            }
            if (
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != null &&
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != ''
            ) {
                try {
                    let imgEmpleado = new Image();
                    imgEmpleado.src = datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma;
                    pdf.addImage(imgEmpleado, x, finalY + 5, 35, 20);
                } catch (error) {
                    console.log(error);
                }
            }

            pdf.text(
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + ' ' +
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1, x, finalY + 25);
            pdf.text(datosPaciente.tblConfPersonasempleados[0].cargo.comaNombrelargo, x, finalY + 28);
            if (datosPaciente.tblConfPersonasempleados[0].coprPieFirma) {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].coprPieFirma, x, finalY + 31);
            } else {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto + ' Nº ' +
                    datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, x, finalY + 31);
            }
            if (!porPagina) {
                if (email) {
                    return pdf.output('blob');
                } else {
                    if (navigator.userAgent.indexOf('irefox') > -1) {

                        pdf.output("dataurlnewwindow");
                    } else {
                        window.open(pdf.output('bloburl'), "_blank");
                    }
                }
            }else { return { pdf, finalY }; }
        } else { return { pdf, finalY }; }

        //FIN ORDENES------------------------------------------------------------------------------------------------------
    }
    getOrdenesCirujia(pdf, ordenes, cuerpo, datosPaciente, finalY, diagnosticos, email = false,porPagina = false) {
        let x = 14;
        if (ordenes.filter(e => e.orden).length > 0) {
            let confOrdenes = JSON.parse(datosPaciente.confOrdenes);
            let cellPadding = this.cellPaddingDefault
            let fontSize = this.fontSizeDefault
            if(confOrdenes.cirujias && confOrdenes.cirujias.estilos){
                cellPadding = confOrdenes.cirujias.estilos.cellPadding??0.2;
                fontSize = confOrdenes.cirujias.estilos.fontSize ??6;
            }
            if (!cuerpo || porPagina) {
                let datos = this.getDatosPaciente(datosPaciente);
                pdf = this.utils.getCabecera(pdf, 14, finalY);
                pdf.line(x, finalY + 25, 195, finalY + 25);
                pdf.setFontSize(10);
                pdf.setFont(undefined, "bold");
                pdf.text('ORDENES', x, finalY + 29);
                if(confOrdenes.cirujias && confOrdenes.cirujias.fechaVencimiento == 'SI'){
                    pdf.text('fecha vencimiento:'+ new Date(datosPaciente.tblCitaFacturaciones[0].cifaFecha).addDays(confOrdenes.cirujias.diasVencimiento).yyyymmdd(), 140, finalY + 29);
                }
                pdf.line(x, finalY + 30, 195, finalY + 30);
                pdf.autoTable({
                    theme:'plain',
                    styles: { cellPadding: cellPadding, fontSize: fontSize, textColor: [0, 0, 0] },
                    startY: finalY + 34,
                    didParseCell: (data) => {
                        if (!(data.row.index % 2)) {
                            data.cell.styles.fontStyle = 'bold'
                        }
                        data.cell.styles.textColor = [0, 0, 0]
                    },
                    head: [
                        [{
                            content: 'DATOS DEL PACIENTE',
                            colSpan: 4,
                            styles: { halign: 'center', valing: 'center',lineColor: [0, 0, 0],lineWidth: 0.2 },
                        }]
                    ],
                    body: datos
                });
                finalY = pdf.previousAutoTable.finalY;
                let d = this.getDiagnosticos(pdf, diagnosticos, true, datosPaciente, finalY);
                pdf = d.pdf;
                finalY = d.finalY;
            }
           
            let head = [
                [{ content: 'ORDENES DE CIRUJIA', colSpan: 4, styles: { halign: 'center', valing: 'center',lineColor: [0, 0, 0],lineWidth: 0.2 }, }],
                ['COD. CUPS','CUPS','CANT.',  'OBSERVACION']
            ];
            let tabla = [];
            ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_CIR').forEach((orden) => {
                tabla.push([orden.tblConfCups[0].cocuCups,orden.tblConfCups[0].cocuNombre,orden.ciorCantidad,  orden.ciorObservacion]);
            });
            if (tabla.length > 0) {
                pdf.autoTable({
                    styles: { cellPadding:cellPadding, fontSize: fontSize, textColor: [0, 0, 0],lineColor: [0, 0, 0],lineWidth: 0.2  },
                     startY: finalY + 5, head: head, showHead: 'firstPage', body: tabla, pageBreak: 'avoid'
                });
                finalY = pdf.previousAutoTable.finalY;
            }


            if (cuerpo && !porPagina) {
                return { pdf, finalY };
            }
            pdf.setFontSize(7);
            pdf.setFont(undefined, "bold");
            let pageHeight = pdf.internal.pageSize.height;
            if ((pageHeight - finalY) < 60) {
                pdf.addPage();
                finalY = 0;
            }
            if (
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != null &&
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != ''
            ) {
                try {
                    let imgEmpleado = new Image();
                    imgEmpleado.src = datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma;
                    pdf.addImage(imgEmpleado, x, finalY + 5, 35, 20);
                } catch (error) {
                    console.log(error);
                }
            }

            pdf.text(
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + ' ' +
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1, x, finalY + 25);
            pdf.text(datosPaciente.tblConfPersonasempleados[0].cargo.comaNombrelargo, x, finalY + 28);
            if (datosPaciente.tblConfPersonasempleados[0].coprPieFirma) {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].coprPieFirma, x, finalY + 31);
            } else {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto + ' Nº ' +
                    datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, x, finalY + 31);
            }
            if (!porPagina) {
                if (email) {
                    return pdf.output('blob');
                } else {
                    if (navigator.userAgent.indexOf('irefox') > -1) {

                        pdf.output("dataurlnewwindow");
                    } else {
                        window.open(pdf.output('bloburl'), "_blank");
                    }
                }
            }else { return { pdf, finalY }; }
        } else { return { pdf, finalY }; }

        //FIN ORDENES------------------------------------------------------------------------------------------------------
    }
    getOrdenesRealizadas(pdf, ordenes, cuerpo, datosPaciente, finalY, diagnosticos, email = false,porPagina = false) {
        let x = 14;
        if (ordenes.filter(e => e.orden).length > 0) {
            let confOrdenes = JSON.parse(datosPaciente.confOrdenes);
            let cellPadding = this.cellPaddingDefault
            let fontSize = this.fontSizeDefault
            if(confOrdenes.realizadas && confOrdenes.realizadas.estilos){
                cellPadding = confOrdenes.realizadas.estilos.cellPadding??0.2;
                fontSize = confOrdenes.realizadas.estilos.fontSize ??6;
            }
            if (!cuerpo || porPagina) {
                let datos = this.getDatosPaciente(datosPaciente);
                pdf = this.utils.getCabecera(pdf, 14, finalY);
                pdf.line(x, finalY + 25, 195, finalY + 25);
                pdf.setFontSize(10);
                pdf.setFont(undefined, "bold");
                pdf.text('ORDENES', x, finalY + 29);
                pdf.line(x, finalY + 30, 195, finalY + 30);
                pdf.autoTable({
                    theme:'plain',
                    styles: { cellPadding: cellPadding, fontSize: fontSize, textColor: [0, 0, 0] },
                    startY: finalY + 34,
                    didParseCell: (data) => {
                        if (!(data.row.index % 2)) {
                            data.cell.styles.fontStyle = 'bold'
                        }
                        data.cell.styles.textColor = [0, 0, 0]
                    },
                    head: [
                        [{
                            content: 'DATOS DEL PACIENTE',
                            colSpan: 4,
                            styles: { halign: 'center', valing: 'center' ,lineColor: [0, 0, 0],lineWidth: 0.2},
                        }]
                    ],
                    body: datos
                });
                finalY = pdf.previousAutoTable.finalY;
                let d = this.getDiagnosticos(pdf, diagnosticos, true, datosPaciente, finalY);
                pdf = d.pdf;
                finalY = d.finalY;
            }
            let head = [
                [{ content: 'ORDENES REALIZADAS', colSpan: 4, styles: { halign: 'center', valing: 'center' ,lineColor: [0, 0, 0],lineWidth: 0.2}, }],
                ['COD. CUPS','CUPS','CANT.',  'TIPO DE ORDEN', 'OBSERVACION']
            ];
            let tabla = [];
            if (ordenes.filter(e => e.orden).length > 0) {
                ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_PRO' || e.orden.comaNombrecorto == 'TP_CUPS_TS').forEach((orden) => {
                    tabla.push([orden.tblConfCups[0].cocuCups,orden.tblConfCups[0].cocuNombre,orden.ciorCantidad, orden.orden.comaNombrelargo,  orden.ciorObservacion]);
                });
            }
            // for (let i = 0; i < ordenes.length; i++) {
            //     let orden = ordenes[i];
            //     tabla.push([orden.tblConfCups[0].cocuNombre, orden.orden.comaNombrelargo, orden.ciorObservacion]);
            // }
            if (tabla.length > 0) {
                pdf.autoTable({
                    theme:'plain',
                    styles: { cellPadding: cellPadding, fontSize: fontSize, textColor: [0, 0, 0],lineColor: [0, 0, 0],lineWidth: 0.2  }, 
                     startY: finalY + 5, head: head, showHead: 'firstPage', body: tabla, pageBreak: 'avoid'
                });
                finalY = pdf.previousAutoTable.finalY;
            }



            if (cuerpo && !porPagina) {
                return { pdf, finalY };
            }
            pdf.setFontSize(7);
            pdf.setFont(undefined, "bold");
            let pageHeight = pdf.internal.pageSize.height;
            if ((pageHeight - finalY) < 60) {
                pdf.addPage();
                finalY = 0;
            }
            if (
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != null &&
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != ''
            ) {
                try {
                    let imgEmpleado = new Image();
                    imgEmpleado.src = datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma;
                    pdf.addImage(imgEmpleado, x, finalY + 5, 35, 20);
                } catch (error) {
                    console.log(error);
                }
            }

            pdf.text(
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + ' ' +
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1, x, finalY + 25);
            pdf.text(datosPaciente.tblConfPersonasempleados[0].cargo.comaNombrelargo, x, finalY + 28);
            if (datosPaciente.tblConfPersonasempleados[0].coprPieFirma) {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].coprPieFirma, x, finalY + 31);
            } else {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto + ' Nº ' +
                    datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, x, finalY + 31);
            }
            if (!porPagina) {
                if (email) {
                    return pdf.output('blob');
                } else {
                    if (navigator.userAgent.indexOf('irefox') > -1) {

                        pdf.output("dataurlnewwindow");
                    } else {
                        window.open(pdf.output('bloburl'), "_blank");
                    }
                }
            }else { return { pdf, finalY }; }
        } else { return { pdf, finalY }; }

        //FIN ORDENES REALIZADAS------------------------------------------------------------------------------------------------------
    }
    getDiagnosticos(pdf, diagnosticos, cuerpo, datosPaciente, finalY, email = false,porPagina = false) {
        let x = 14;
        if (diagnosticos.length > 0) {
            let confOrdenes = JSON.parse(datosPaciente.confOrdenes);
            let cellPadding = this.cellPaddingDefault
            let fontSize = this.fontSizeDefault
            if(confOrdenes.diagnosticos && confOrdenes.diagnosticos.estilos){
                cellPadding = confOrdenes.diagnosticos.estilos.cellPadding??0.2;
                fontSize = confOrdenes.diagnosticos.estilos.fontSize ??6;
            }
            //DIAGNOSTICOS------------------------------------------------------------------------------------------------------
            if (!cuerpo || porPagina) {
                let datos = this.getDatosPaciente(datosPaciente);
                pdf = this.utils.getCabecera(pdf, 14, finalY);
                pdf.line(x, finalY + 25, 195, finalY + 25);
                pdf.setFontSize(10);
                pdf.setFont(undefined, "bold");
                pdf.text('DIAGNOSTICOS', x, finalY + 29);
                pdf.line(x, finalY + 30, 195, finalY + 30);
                pdf.autoTable({
                    theme:'plain',
                    styles: { cellPadding: cellPadding, fontSize: fontSize, textColor: [0, 0, 0] },
                    startY: finalY + 34,
                    didParseCell: (data) => {
                        if (!(data.row.index % 2)) {
                            data.cell.styles.fontStyle = 'bold'
                        }
                        data.cell.styles.textColor = [0, 0, 0]
                    },
                    head: [
                        [{
                            content: 'DATOS DEL PACIENTE',
                            colSpan: 4,
                            styles: { halign: 'center', valing: 'center' ,lineColor: [0, 0, 0],lineWidth: 0.2},
                        }]
                    ],
                    body: datos
                });
                finalY = pdf.previousAutoTable.finalY;
            }
            let head = [
                [{ content: 'DIAGNOSTICOS', colSpan: 4, styles: { halign: 'center', valing: 'center' ,lineColor: [0, 0, 0],lineWidth: 0.2}, }],
                ['CODIGO', 'TIPO DE  CIE 10', 'TIPO DE DIAGNOSTICO', 'TIPO DE CLASE']
            ];
            let tabla = [];
            diagnosticos.forEach((diagnostico) => {
                tabla.push([(diagnostico.cie10) ? diagnostico.cie10.comaNombrecorto : '', (diagnostico.cie10) ? diagnostico.cie10.comaNombrelargo : '', (diagnostico.diagnostico) ? diagnostico.diagnostico.comaNombrelargo : '', (diagnostico.clase) ? diagnostico.clase.comaNombrelargo : '']);
            });

            pdf.autoTable({
                theme:'plain',
                styles: { cellPadding: cellPadding, fontSize: fontSize, textColor: [0, 0, 0] ,lineColor: [0, 0, 0],lineWidth: 0.2 },
                 startY: finalY + 5, head: head, showHead: 'firstPage', body: tabla, pageBreak: 'avoid'
            });
            finalY = pdf.previousAutoTable.finalY;
            if (cuerpo && !porPagina) {
                return { pdf, finalY };
            }
            pdf.setFontSize(7);
            pdf.setFont(undefined, "bold");
            let pageHeight = pdf.internal.pageSize.height;
            if ((pageHeight - finalY) < 60) {
                pdf.addPage();
                finalY = 0;
            }
            if (
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != null &&
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != ''
            ) {
                try {
                    let imgEmpleado = new Image();
                    imgEmpleado.src = datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma;
                    pdf.addImage(imgEmpleado, x, finalY + 5, 35, 20);
                } catch (error) {
                    console.log(error);
                }
            }

            pdf.text(
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + ' ' +
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1, x, finalY + 25);
            pdf.text(datosPaciente.tblConfPersonasempleados[0].cargo.comaNombrelargo, x, finalY + 28);
            if (datosPaciente.tblConfPersonasempleados[0].coprPieFirma) {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].coprPieFirma, x, finalY + 31);
            } else {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto + ' Nº ' +
                    datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, x, finalY + 31);
            }
            if (!porPagina) {
                if (email) {
                    return pdf.output('blob');
                } else {
                    if (navigator.userAgent.indexOf('irefox') > -1) {

                        pdf.output("dataurlnewwindow");
                    } else {
                        window.open(pdf.output('bloburl'), "_blank");
                    }
                }
            }else { return { pdf, finalY }; }
        } else { return { pdf, finalY }; }

        //FIN DIAGNOSTICOS------------------------------------------------------------------------------------------------------
    }
    getRemisiones(pdf, remisiones, cuerpo, datosPaciente, finalY, diagnosticos, email = false,porPagina = false) {
        let x = 14;
        //REMISIONES------------------------------------------------------------------------------------------------------
        if (remisiones.length > 0) {
            let confOrdenes = JSON.parse(datosPaciente.confOrdenes);
            let cellPadding = this.cellPaddingDefault
            let fontSize = this.fontSizeDefault
            if(confOrdenes.remisiones && confOrdenes.remisiones.estilos){
                cellPadding = confOrdenes.remisiones.estilos.cellPadding;
                fontSize = confOrdenes.remisiones.estilos.fontSize;
            }
            if (!cuerpo || porPagina) {
                let datos = this.getDatosPaciente(datosPaciente);
                pdf = this.utils.getCabecera(pdf, 14, finalY);
                pdf.line(x, finalY + 25, 195, finalY + 25);
                pdf.setFontSize(10);
                pdf.setFont(undefined, "bold");
                pdf.text('REMISIONES', x, finalY + 29);
                
                if(confOrdenes.remisiones && confOrdenes.remisiones.fechaVencimiento == 'SI'){
                    pdf.text('fecha vencimiento:'+ new Date(datosPaciente.tblCitaFacturaciones[0].cifaFecha).addDays(confOrdenes.remisiones.diasVencimiento).yyyymmdd(), 140, finalY + 29);
                }
                pdf.line(x, finalY + 30, 195, finalY + 30);
                pdf.autoTable({
                    theme:'plain',
                    styles: { cellPadding: cellPadding, fontSize: fontSize, textColor: [0, 0, 0] },
                    startY: finalY + 34,
                    didParseCell: (data) => {
                        if (!(data.row.index % 2)) {
                            data.cell.styles.fontStyle = 'bold'
                        }
                        data.cell.styles.textColor = [0, 0, 0]
                    },
                    head: [
                        [{
                            content: 'DATOS DEL PACIENTE',
                            colSpan: 4,
                            styles: { halign: 'center', valing: 'center' ,lineColor: [0, 0, 0],lineWidth: 0.2},
                        }]
                    ],
                    body: datos
                });
                finalY = pdf.previousAutoTable.finalY;
                let d = this.getDiagnosticos(pdf, diagnosticos, true, datosPaciente, finalY);
                pdf = d.pdf;
                finalY = d.finalY;
            }
            let head = [
                [{ content: 'REMISIONES', colSpan: 3, styles: { halign: 'center', valing: 'center',lineColor: [0, 0, 0],lineWidth: 0.2 }, }],
                ['OBSERVACIONES', 'SERVICIO', 'CODIGO']
            ];
            let tabla = [];
            for (let i = 0; i < remisiones.length; i++) {
                let remision = remisiones[i];
                tabla.push([remision.cirmObservacion, remision.tblConfCup.cocuNombre, remision.tblConfCup.cocuCups]);
            }


            pdf.autoTable({
                theme:'plain',
                styles: { cellPadding: cellPadding, fontSize: fontSize, textColor: [0, 0, 0],lineColor: [0, 0, 0],lineWidth: 0.2  },
                 startY: finalY + 5, head: head, showHead: 'firstPage', body: tabla, pageBreak: 'avoid'
            });
            finalY = pdf.previousAutoTable.finalY;
            if (cuerpo && !porPagina) {
                return { pdf, finalY };
            }
            pdf.setFontSize(7);
            pdf.setFont(undefined, "bold");
            let pageHeight = pdf.internal.pageSize.height;
            if ((pageHeight - finalY) < 60) {
                pdf.addPage();
                finalY = 0;
            }
            if (
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != null &&
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != ''
            ) {
                try {
                    let imgEmpleado = new Image();
                    imgEmpleado.src = datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma;
                    pdf.addImage(imgEmpleado, x, finalY + 5, 35, 20);
                } catch (error) {
                    console.log(error);
                }
            }

            pdf.text(
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + ' ' +
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1, x, finalY + 25);
            pdf.text(datosPaciente.tblConfPersonasempleados[0].cargo.comaNombrelargo, x, finalY + 28);
            if (datosPaciente.tblConfPersonasempleados[0].coprPieFirma) {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].coprPieFirma, x, finalY + 31);
            } else {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto + ' Nº ' +
                    datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, x, finalY + 31);
            }
            if (!porPagina) {
                if (email) {
                    return pdf.output('blob');
                } else {
                    if (navigator.userAgent.indexOf('irefox') > -1) {

                        pdf.output("dataurlnewwindow");
                    } else {
                        window.open(pdf.output('bloburl'), "_blank");
                    }
                }
            }else { return { pdf, finalY }; }

        } else { return { pdf, finalY }; }

        //FIN REMISIONES------------------------------------------------------------------------------------------------------
    }
    getIncapacidades(pdf, incapacidades, cuerpo, datosPaciente, finalY, diagnosticos, email = false,porPagina = false) {
        let x = 14;
        //INCAPACIDADES------------------------------------------------------------------------------------------------------
        try {
            if (incapacidades.length > 0) {
                let confOrdenes = JSON.parse(datosPaciente.confOrdenes);
                let cellPadding = this.cellPaddingDefault
                let fontSize = this.fontSizeDefault
                if(confOrdenes.incapacidades && confOrdenes.incapacidades.estilos){
                    cellPadding = confOrdenes.incapacidades.estilos.cellPadding;
                    fontSize = confOrdenes.incapacidades.estilos.fontSize;
                }
                for (const incapacidad of incapacidades) {
    
                    if (!cuerpo || porPagina) {
                        let datos = this.getDatosPaciente(datosPaciente);
                        pdf = this.utils.getCabecera(pdf, 14, finalY);
                        pdf.line(x, finalY + 25, 195, finalY + 25);
                        pdf.setFontSize(10);
                        pdf.setFont(undefined, "bold");
                        pdf.text('INCAPACIDADES', x, finalY + 29);
                        pdf.line(x, finalY + 30, 195, finalY + 30);
                        pdf.autoTable({
                            theme:'plain',
                            styles: { cellPadding: cellPadding, fontSize: fontSize, textColor: [0, 0, 0] },
                            startY: finalY + 34,
                            didParseCell: (data) => {
                                if (!(data.row.index % 2)) {
                                    data.cell.styles.fontStyle = 'bold'
                                }
                                data.cell.styles.textColor = [0, 0, 0]
                            },
                            head: [
                                [{
                                    content: 'DATOS DEL PACIENTE',
                                    colSpan: 4,
                                    styles: { halign: 'center', valing: 'center' ,lineColor: [0, 0, 0],lineWidth: 0.2},
                                }]
                            ],
                            body: datos
                        });
                        finalY = pdf.previousAutoTable.finalY;
                        let d = this.getDiagnosticos(pdf, diagnosticos, true, datosPaciente, finalY);
                        pdf = d.pdf;
                        finalY = d.finalY;
                    }
                    let head = [
                        [{ content: 'INCAPACIDAD', colSpan: 3, styles: { halign: 'center', valing: 'center',lineColor: [0, 0, 0],lineWidth: 0.2 }, }],
                        ['FECHA INICIO', 'DIAS', 'FECHA FINAL']
                    ];
                    let tabla = [];
    
                    tabla.push([incapacidad.ciceFechainicio, incapacidad.ciceDias, incapacidad.ciceFechafinal]);
                    pdf.autoTable({
                        theme:'plain',
                        styles: { cellPadding: cellPadding, fontSize: fontSize, textColor: [0, 0, 0] ,lineColor: [0, 0, 0],lineWidth: 0.2 },
                         startY: finalY + 5, head: head, showHead: 'firstPage', body: tabla, pageBreak: 'avoid'
                    });
                    finalY = pdf.previousAutoTable.finalY;
                    if (cuerpo && !porPagina) {
                        return { pdf, finalY };
                    }
                    pdf.setFontSize(7);
                    pdf.setFont(undefined, "bold");
                    let pageHeight = pdf.internal.pageSize.height;
                    if ((pageHeight - finalY) < 60) {
                        pdf.addPage();
                        finalY = 0;
                    }
                    try {
                        if (datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma) {
                            const imgEmpleado = new Image();
                            imgEmpleado.src = datosPaciente.tblConfPersonasempleados[0]
                                .tblConfPersonasnaturale
                                .tblConfPersona
                                .copeFirma;
                        
                            imgEmpleado.onload = () => {
                                pdf.addImage(imgEmpleado, 14, finalY + 10, 50, 30);
                            };
                        }
                        if (datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeFirmaPaciente) {
                            const imgPaciente = new Image();
                            imgPaciente.src = datosPaciente.tblConfPersonaspacientes[0]
                                .tblConfPersonasnaturale
                                .tblConfPersona
                                .copeFirmaPaciente;
                        
                            imgPaciente.onload = () => {
                                pdf.addImage(imgPaciente, 74, finalY + 10, 50, 30);
                            };
                        }
                        finalY += 50;
                        const centerTextHorizontally = (text, pageWidth) => {
                            const textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
                            return (pageWidth - textWidth) / 2;
                        };
                        const pageWidth = pdf.internal.pageSize.width;
                        const nombreCompleto = `${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1} ${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1}`;
                        const cargo = datosPaciente.tblConfPersonasempleados[0].cargo.comaNombrelargo;
                        
                        const nombreX = centerTextHorizontally(nombreCompleto, pageWidth);
                        const cargoX = centerTextHorizontally(cargo, pageWidth);

                        pdf.text(nombreCompleto, nombreX, finalY + 25);
                        pdf.text(cargo, cargoX, finalY + 28);

                        if (datosPaciente.tblConfPersonasempleados[0].coprPieFirma) {
                            const pieFirma = datosPaciente.tblConfPersonasempleados[0].coprPieFirma;
                            const pieFirmaX = centerTextHorizontally(pieFirma, pageWidth);
                            pdf.text(pieFirma, pieFirmaX, finalY + 31);
                        } else {
                            const identificacion = `${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto} Nº ${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}`;
                            const identificacionX = centerTextHorizontally(identificacion, pageWidth);
                            pdf.text(identificacion, identificacionX, finalY + 31);
                        }

                    } catch (error) {
                        console.error('Error al procesar las firmas:', error);
                    }
    
                    if (incapacidades.length > 1) {
                        pdf.addPage();
                        finalY = 2;
                    }
                }
                // Mostrar la firma del empleado
                this.setFirmas(datosPaciente, pdf, x, finalY);
                if (email) {
                    return pdf.output('blob');
                } else {
                    if (navigator.userAgent.indexOf('irefox') > -1) {
    
                        pdf.output("dataurlnewwindow");
                    } else {
                        window.open(pdf.output('bloburl'), "_blank");
                    }
                }
            } else { return { pdf, finalY }; }
        } catch (error) {
            console.log(error);
        }
    
        //FIN INCAPACIDADES------------------------------------------------------------------------------------------------------
    }
    
    getCertificados(pdf, certificados, cuerpo, datosPaciente, finalY, diagnosticos, email = false,porPagina= false) {
        let x = 14;
        //CERTIFICADOS------------------------------------------------------------------------------------------------------
        if (certificados.length > 0) {
            let confOrdenes = JSON.parse(datosPaciente.confOrdenes);
                let cellPadding = this.cellPaddingDefault
                let fontSize = this.fontSizeDefault
                if(confOrdenes.certificados && confOrdenes.certificados.estilos){
                    cellPadding = confOrdenes.certificados.estilos.cellPadding;
                    fontSize = confOrdenes.certificados.estilos.fontSize;
                }
            if (!cuerpo || porPagina) {
                let datos = this.getDatosPaciente(datosPaciente);
                pdf = this.utils.getCabecera(pdf, 14, finalY);
                pdf.line(x, finalY + 25, 195, finalY + 25);
                pdf.setFontSize(10);
                pdf.setFont(undefined, "bold");
                pdf.text('CERTIFICADOS', x, finalY + 29);
                pdf.line(x, finalY + 30, 195, finalY + 30);
                pdf.autoTable({
                    theme:'plain',
                    styles: { cellPadding: cellPadding, fontSize: fontSize, textColor: [0, 0, 0] },
                    startY: finalY + 34,
                    didParseCell: (data) => {
                        if (!(data.row.index % 2)) {
                            data.cell.styles.fontStyle = 'bold'
                        }
                        data.cell.styles.textColor = [0, 0, 0]
                    },
                    head: [
                        [{
                            content: 'DATOS DEL PACIENTE',
                            colSpan: 4,
                            styles: { halign: 'center', valing: 'center' ,lineColor: [0, 0, 0],lineWidth: 0.2},
                        }]
                    ],
                    body: datos
                });
                finalY = pdf.previousAutoTable.finalY;
                let d = this.getDiagnosticos(pdf, diagnosticos, true, datosPaciente, finalY);
                pdf = d.pdf;
                finalY = d.finalY;
            }
            let head = [
                [{ content: 'CERTIFICADO', styles: { halign: 'center', valing: 'center' ,lineColor: [0, 0, 0],lineWidth: 0.2}, }],
            ];
            let tabla = [];
            for (let i = 0; i < certificados.length; i++) {
                let certificado = certificados[i];
                tabla.push([certificado.ciceCertificado]);
            }


            pdf.autoTable({
                theme:'plain',
                styles: { cellPadding: cellPadding, fontSize: fontSize, textColor: [0, 0, 0],lineColor: [0, 0, 0],lineWidth: 0.2  },
                 startY: finalY + 10, head: head, showHead: 'firstPage', body: tabla, pageBreak: 'avoid'
            });
            finalY = pdf.previousAutoTable.finalY;
            if (cuerpo && !porPagina) {
                return { pdf, finalY };
            }
            pdf.setFontSize(7);
            pdf.setFont(undefined, "bold");
            let pageHeight = pdf.internal.pageSize.height;
            if ((pageHeight - finalY) < 60) {
                pdf.addPage();
                finalY = 0;
            }
            if (
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != null &&
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != ''
            ) {
                try {
                    let imgEmpleado = new Image();
                    imgEmpleado.src = datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma;
                    pdf.addImage(imgEmpleado, x, finalY + 5, 35, 20);
                } catch (error) {
                    console.log(error);
                }
            }

            pdf.text(
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + ' ' +
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1, x, finalY + 25);
            pdf.text(datosPaciente.tblConfPersonasempleados[0].cargo.comaNombrelargo, x, finalY + 28);
            if (datosPaciente.tblConfPersonasempleados[0].coprPieFirma) {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].coprPieFirma, x, finalY + 31);
            } else {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto + ' Nº ' +
                    datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, x, finalY + 31);
            }
            if (!porPagina) {
                if (email) {
                    return pdf.output('blob');
                } else {
                    if (navigator.userAgent.indexOf('irefox') > -1) {

                        pdf.output("dataurlnewwindow");
                    } else {
                        window.open(pdf.output('bloburl'), "_blank");
                    }
                }
            }else { return { pdf, finalY }; }

        } else { return { pdf, finalY }; }

        //FIN CERTIFICADOS------------------------------------------------------------------------------------------------------
    }
    getRecetarios(pdf, recetarios, cuerpo, datosPaciente, finalY, diagnosticos, email = false,porPagina= false) {
        let x = 14;
        //RECETARIOS------------------------------------------------------------------------------------------------------
        if (recetarios.length > 0) {
            let confOrdenes = JSON.parse(datosPaciente.confOrdenes);
            let cellPadding = this.cellPaddingDefault
            let fontSize = this.fontSizeDefault
            if(confOrdenes.recetarios && confOrdenes.recetarios.estilos){
                cellPadding = confOrdenes.recetarios.estilos.cellPadding;
                fontSize = confOrdenes.recetarios.estilos.fontSize;
            }
            if (!cuerpo || porPagina) {
                let datos = this.getDatosPaciente(datosPaciente);
                pdf = this.utils.getCabecera(pdf, 14, finalY);
                pdf.line(x, finalY + 25, 195, finalY + 25);
                pdf.setFontSize(8);
                pdf.setFont(undefined, "bold");
                pdf.text('FÓRMULA MÉDICA', x, finalY + 29);
                if(confOrdenes.recetarios && confOrdenes.recetarios.fechaVencimiento == 'SI'){
                    pdf.text('fecha vencimiento:'+ new Date(datosPaciente.tblCitaFacturaciones[0].cifaFecha).addDays(confOrdenes.recetarios.diasVencimiento).yyyymmdd(), 140, finalY + 29);
                }
                pdf.line(x, finalY + 30, 195, finalY + 30);
                pdf.autoTable({
                    theme:'plain',
                    styles: { cellPadding: cellPadding, fontSize: fontSize, textColor: [0, 0, 0] },
                    startY: finalY + 34,
                    didParseCell: (data) => {
                        if (!(data.row.index % 2)) {
                            data.cell.styles.fontStyle = 'bold'
                        }
                        data.cell.styles.textColor = [0, 0, 0]
                    },
                    head: [
                        [{
                            content: 'DATOS DEL PACIENTE',
                            colSpan: 4,
                            styles: { halign: 'center', valing: 'center',lineColor: [0, 0, 0],lineWidth: 0.2 },
                        }]
                    ],
                    body: datos
                });
                finalY = pdf.previousAutoTable.finalY;
                let d = this.getDiagnosticos(pdf, diagnosticos, true, datosPaciente, finalY);
                pdf = d.pdf;
                finalY = d.finalY;
            }
            let head = [
                [{ content: 'FÓRMULA MÉDICA', /*colSpan: 4,*/ styles: { halign: 'center', valing: 'center',lineColor: [0, 0, 0],lineWidth: 0.2 }, }],
                //['MEDICAMENTO', 'FRECUENCIA DE HORAS', 'DURACION(DIAS)', 'CANTIDAD']
            ];
            let tabla = [];
            for (let i = 0; i < recetarios.length; i++) {
                let recetario = recetarios[i];
                let escrito = recetario.tblMediMedicamento.mediNombre + ' - FORMA:' + recetario.tblMediMedicamento.mediFormafarmaceutica+' - CONCENTRACION:'+ recetario.tblMediMedicamento.mediConcentracion + ' - UNID:' + recetario.tblMediMedicamento.unidad.comaNombrecorto + ',' + recetario.cireCantidaddosis + ' cada ' + recetario.cireFrecuenciahoras + ' horas, durante ' + recetario.cireDuraciondias + ' dias, la cantidad de ' + recetario.cireCantidadprescrita + ', via '+recetario.tblMediMedicamento.via.comaNombrecorto;
                if (recetario.cireDetalles) {
                    escrito = escrito + ', Prescripcion: \n';
                    pdf.splitTextToSize(recetario.cireDetalles, 80).forEach(element => escrito = escrito + element + '\n');
                }
                tabla.push([{ content: escrito/*, colSpan: 4 */ }]);
            }

            pdf.autoTable({
                theme:'plain',
                styles: { cellPadding: cellPadding, fontSize: fontSize, overflow: 'linebreak', textColor: [0, 0, 0],lineColor: [0, 0, 0],lineWidth: 0.2  },
                 startY: finalY + 5, head: head, showHead: 'firstPage', body: tabla, pageBreak: 'auto'
            });
            finalY = pdf.previousAutoTable.finalY;

            if (cuerpo && !porPagina) {
                return { pdf, finalY };
            }
            pdf.setFontSize(7);
            pdf.setFont(undefined, "bold");
            let pageHeight = pdf.internal.pageSize.height;
            if ((pageHeight - finalY) < 50) {
                pdf.addPage();
                finalY = 0;
            }
            if (
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != null &&
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != ''
            ) {
                try {
                    let imgEmpleado = new Image();
                    imgEmpleado.src = datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma;
                    pdf.addImage(imgEmpleado, x, finalY + 5, 35, 20);
                } catch (error) {
                    console.log(error);
                }
            }

            pdf.text(
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + ' ' +
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1, x, finalY + 25);
            pdf.text(datosPaciente.tblConfPersonasempleados[0].cargo.comaNombrelargo, x, finalY + 28);
            if (datosPaciente.tblConfPersonasempleados[0].coprPieFirma) {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].coprPieFirma, x, finalY + 31);
            } else {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto + ' Nº ' +
                    datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, x, finalY + 31);
            }
            if (!porPagina) {
                if (email) {
                    return pdf.output('blob');
                } else {
                    if (navigator.userAgent.indexOf('irefox') > -1) {

                        pdf.output("dataurlnewwindow");
                    } else {
                        window.open(pdf.output('bloburl'), "_blank");
                    }
                }
            }else { return { pdf, finalY }; }
        } else { return { pdf, finalY }; }

        //FIN RECETARIOS------------------------------------------------------------------------------------------------------
    }
    getVacunas(pdf, vacunas, cuerpo, datosPaciente, finalY, diagnosticos,porPagina = false) {
        let x = 14;
        //VACUNAS------------------------------------------------------------------------------------------------------
        if (vacunas.length > 0) {
            let confOrdenes = JSON.parse(datosPaciente.confOrdenes);
            let cellPadding = this.cellPaddingDefault
            let fontSize = this.fontSizeDefault
            if(confOrdenes.vacunas && confOrdenes.vacunas.estilos){
                cellPadding = confOrdenes.vacunas.estilos.cellPadding;
                fontSize = confOrdenes.vacunas.estilos.fontSize;
            }
            if (!cuerpo || porPagina) {
                let datos = this.getDatosPaciente(datosPaciente);
                pdf = this.utils.getCabecera(pdf, 14, finalY);
                pdf.line(x, finalY + 34, 195, finalY + 34);
                pdf.setFontSize(10);
                pdf.setFont(undefined, "bold");
                pdf.text('VACUNAS', x, finalY + 38);
                pdf.line(x, finalY + 39, 195, finalY + 39);
                pdf.autoTable({
                    theme:'plain',
                    styles: { cellPadding: cellPadding, fontSize: fontSize, textColor: [0, 0, 0] },
                    startY: finalY + 40,
                    didParseCell: (data) => {
                        if (!(data.row.index % 2)) {
                            data.cell.styles.fontStyle = 'bold'
                        }
                        data.cell.styles.textColor = [0, 0, 0]
                    },
                    head: [
                        [{
                            content: 'DATOS DEL PACIENTE',
                            colSpan: 4,
                            styles: { halign: 'center', valing: 'center',lineColor: [0, 0, 0],lineWidth: 0.2 },
                        }]
                    ],
                    body: datos
                });
                finalY = pdf.previousAutoTable.finalY;
                let d = this.getDiagnosticos(pdf, diagnosticos, true, datosPaciente, finalY);
                pdf = d.pdf;
                finalY = d.finalY;
            }
            let head = [
                [{ content: 'VACUNAS', colSpan: 8, styles: { halign: 'center', valing: 'center',lineColor: [0, 0, 0],lineWidth: 0.2 }, }],
                ['VACUNA', 'CANTIDAD', 'DOSIS', 'LAB', 'LOTE', 'FEC PRÓXIMA CITA', 'ESTADO', 'FEC DE REALIZADO']
            ];
            let tabla = [];
            for (let i = 0; i < vacunas.length; i++) {
                let vacuna = vacunas[i];
                tabla.push([vacuna.VACUNA, vacuna.CANTIDAD, vacuna.DOSIS, vacuna.LABORATORIO, vacuna.LOTE, vacuna['FECHA PRÓXIMA CITA'], vacuna.ESTADO, vacuna['FECHA DE REALIZADO']]);

            }


            pdf.autoTable({
                theme:'plain',
                styles: { cellPadding:cellPadding, fontSize: fontSize, overflow: 'linebreak', textColor: [0, 0, 0],lineColor: [0, 0, 0],lineWidth: 0.2  },
                startY: finalY + 10, head: head, showHead: 'firstPage', body: tabla, pageBreak: 'avoid'
            });
            finalY = pdf.previousAutoTable.finalY;

            if (cuerpo && !porPagina) {
                return { pdf, finalY };
            }
            pdf.setFontSize(7);
            pdf.setFont(undefined, "bold");
            let pageHeight = pdf.internal.pageSize.height;
            if ((pageHeight - finalY) < 60) {
                pdf.addPage();
                finalY = 0;
            }
            if (
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != null &&
                datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeFirmaPaciente != null &&
                datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeFirmaPaciente != ''
            ) {
                try {

                    let imgEmpleado = new Image();
                    imgEmpleado.src = datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma;
            
                    let imgPaciente = new Image();
                    imgPaciente.src = datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeFirmaPaciente;
            
                    pdf.addImage(imgEmpleado, x, finalY + 20, 40, 20);
                    pdf.addImage(imgPaciente, x + 40, finalY + 20, 40, 20); // Ajusta la posición según necesidad
                } catch (error) {
                    console.log(error);
                }
            }

            pdf.text(
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + ' ' +
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1, x, finalY + 40);
            pdf.text(datosPaciente.tblConfPersonasempleados[0].cargo.comaNombrelargo, x, finalY + 44);
            if (datosPaciente.tblConfPersonasempleados[0].coprPieFirma) {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].coprPieFirma, x, finalY + 48);
            } else {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto + ' Nº ' +
                    datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, x, finalY + 48);
            }
            if (navigator.userAgent.indexOf('irefox') > -1) {

                pdf.output("dataurlnewwindow");
            } else {
                window.open(pdf.output('bloburl'), "_blank");
            }
        } else { return { pdf, finalY }; }

        //FIN VACUNAS------------------------------------------------------------------------------------------------------
    }
    async getHistoria(pdf,props) {
        let  { datosPaciente, secciones, ordenes, diagnosticos, remisiones, recetarios, historial, pesos, incapacidades, certificados, vacunas, imagenesodontologia, cuerpo, email = false,configHistoria , dataSource} = props; 
        let sexo =  datosPaciente.tblConfPersonaspacientes[0].sexo.comaNombrecorto;
        let datos = this.getDatosPaciente(datosPaciente,'historia');
        configHistoria = JSON.parse(configHistoria);
        let x = 14;
        let finalY = 2;
        let cellPadding = 0.2
        let fontSize = this.fontSizeDefault
        if(configHistoria && configHistoria.estilos){
            cellPadding = configHistoria.estilos.cellPadding;
            fontSize = configHistoria.estilos.fontSize;
        }
        pdf = this.utils.getCabecera(pdf, x, finalY);
        pdf.line(x, finalY + 25, 195, finalY + 25);
        pdf.setFontSize(10);
        pdf.setFont(undefined, "bold");
        pdf.text(datosPaciente.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre, x, finalY + 29);
        pdf.line(x, finalY + 30, 195, finalY + 30);
        //pdf.setFont("normal");
        pdf.autoTable({
            theme:'plain',
            styles: { cellPadding: cellPadding, fontSize: fontSize, textColor: [0, 0, 0] },
            startY: finalY + 31,
            didParseCell: (data) => {
                if (!(data.row.index % 2)) {
                    data.cell.styles.fontStyle = 'bold'
                }
                data.cell.styles.textColor = [0, 0, 0]
            },
            showHead: 'firstPage',
            head: [
                [{
                    content: 'DATOS DEL PACIENTE',
                    colSpan: 4,
                    styles: { halign: 'center', valing: 'center',lineColor: [0, 0, 0],lineWidth: 0.2 },
                }]
            ],
            body: datos
        });

        secciones.filter(e => e.hiseCodigo != 'UTSG_S15' && e.hiseImpresion == 1).forEach((seccion) => {
            let head = [
                [{
                    content: seccion.hiseNombre,
                    colSpan: 12,
                    styles: { halign: 'center', valing: 'center',lineColor: [0, 0, 0],lineWidth: 0.2 },
                }]
            ];
            let tabla = [];
            let row = [];
            let rowt = [];

            let ultimo = 0;
            if (seccion.campos.length > 0) {
                seccion.campos.filter(a =>  a.tblConfHistoriaseccione.hiseImpresion == 1).forEach((campo, index) => {
                    if (seccion.hiseHistorico && index == 0) {
                        row.push({ content: datosPaciente.tblCitaFacturaciones[0].cifaFecha, colSpan: 12, styles: { halign: 'left', valing: 'center', fontStyle: 'bold' } });
                        tabla.push(row);
                        row = null;
                        row = [];
                    }
                    if (row.length > 0 && rowt.length > 0 && (parseInt(campo.tblConfHistoriaseccione.hiseCol) + ultimo) > 11) {

                        rowt[(rowt.length - 1)].colSpan = rowt[(rowt.length - 1)].colSpan + (12 - ultimo);
                        row[(row.length - 1)].colSpan = row[(row.length - 1)].colSpan + (12 - ultimo);
                        tabla.push(rowt);
                        tabla.push(row);
                        rowt = null;
                        rowt = [];
                        row = null;
                        row = [];
                        ultimo = 0;
                    }
                    ultimo = parseInt(campo.tblConfHistoriaseccione.hiseCol) + ultimo;
                    let val = '';
                    let valor = campo.tblConfHistoriaseccione.hiseNombre + ': ';
                    pdf.splitTextToSize(valor, parseInt(campo.tblConfHistoriaseccione.hiseCol) * 13).forEach(element => val = val + element + '\n');
                    rowt.push({ content: valor, colSpan: parseInt(campo.tblConfHistoriaseccione.hiseCol), styles: { fontStyle: 'bold' } });

                    let val1 = '';
                    valor = ((campo.tblConfHistoriaseccione.tipodato.comaNombrecorto == 'LISTA') ? `${this.utils.getValues(campo.tblConfHistoriaseccione.hiseValores, campo.cihfValor)}` : (campo.cihfValor ? (campo.cihfValor + '') : ''));
                    pdf.splitTextToSize(valor, parseInt(campo.tblConfHistoriaseccione.hiseCol) * 14).forEach(element => val1 = val1 + element + '\n');
                    row.push({ content: valor, colSpan: parseInt(campo.tblConfHistoriaseccione.hiseCol), styles: {} });

                    if (seccion.campos.length >= 4) {
                        if (ultimo == 12) {
                            ultimo = 0;
                            tabla.push(rowt);
                            tabla.push(row);
                            rowt = null;
                            rowt = [];
                            row = null;
                            row = [];
                        } else {
                            if ((index + 1) == seccion.campos.length) {
                                ultimo = 0;
                                rowt[(rowt.length - 1)].colSpan = rowt[(rowt.length - 1)].colSpan + (12 - rowt[(rowt.length - 1)].colSpan);
                                row[(row.length - 1)].colSpan = row[(row.length - 1)].colSpan + (12 - row[(row.length - 1)].colSpan);
                                tabla.push(rowt);
                                tabla.push(row);
                                rowt = null;
                                rowt = [];
                                row = null;
                                row = [];
                            }
                        }

                    } else {
                        if ((index + 1) == seccion.campos.length) {
                            ultimo = 0;
                            tabla.push(rowt);
                            tabla.push(row);
                            rowt = null;
                            rowt = [];
                            row = null;
                            row = [];
                        }
                    }
                    if (((index + 1) % 10) == 0) {
                        finalY = pdf.previousAutoTable.finalY;

                        pdf.autoTable({
                            theme:'plain',
                            styles: { cellPadding: 0.2, fontSize: 6, textColor: [0, 0, 0], overflow: 'linebreak' },
                            startY: (((index + 1) - 10) == 0) ? (finalY + 5) : finalY, head: (((index + 1) - 10) == 0) ? head : [], body: tabla, showHead: 'firstPage', pageBreak: 'auto'
                        });
                        finalY = pdf.previousAutoTable.finalY;
                        tabla = [];
                    } else {
                        if ((index + 1) == seccion.campos.length && seccion.campos.length < 10) {

                            finalY = pdf.previousAutoTable.finalY;
                            pdf.autoTable({
                                theme:'plain',
                                styles: { cellPadding: cellPadding, fontSize:fontSize, textColor: [0, 0, 0], overflow: 'linebreak' },
                                 startY: finalY + 5, head: head, body: tabla, showHead: 'firstPage', pageBreak: 'auto'
                            });
                            finalY = pdf.previousAutoTable.finalY;
                            tabla = [];
                        } else {
                            if ((index + 1) == seccion.campos.length && tabla.length > 0) {

                                finalY = pdf.previousAutoTable.finalY;
                                pdf.autoTable({
                                    theme:'plain',
                                    styles: { cellPadding: cellPadding, fontSize:fontSize, textColor: [0, 0, 0], overflow: 'linebreak' },
                                     startY: finalY, head: [], body: tabla, showHead: 'firstPage', pageBreak: 'auto'
                                });
                                finalY = pdf.previousAutoTable.finalY;
                                tabla = [];
                            }
                        }

                    }
                });

                rowt = null;
                rowt = [];
                row = null;
                row = [];

                if (seccion.hiseHistorico) {
                    // tabla[0][0].rowSpan = ultimo;
                    ultimo = 0;
                    if (seccion.campos.length > 0) {
                        // seccion.campos.sort((a, b) => a.hiseOrden - b.hiseOrden);
                        let coindex = 0;
                        historial.filter(e => e.tblConfHistoriaseccione.hiseIdDependencia == seccion.hiseId).forEach((campo, cindex) => {
                            let cantidad = historial.filter(e => e.tblConfHistoriaseccione.hiseIdDependencia == seccion.hiseId && e.tblCitaFacturacione.cifaId == campo.tblCitaFacturacione.cifaId).length;
                            if (cindex == 0 || historial.filter(e => e.tblConfHistoriaseccione.hiseIdDependencia == seccion.hiseId)[(cindex > 0) ? cindex - 1 : cindex].tblCitaFacturacione.cifaId != campo.tblCitaFacturacione.cifaId) {
                                coindex = 0;
                                tabla.push([{ content: campo.tblCitaFacturacione.cifaFecha, colSpan: 12, styles: { halign: 'left', valing: 'center', fontStyle: 'bold' } }]);
                            }
                            coindex = coindex +1;
                            if (row.length > 0 && rowt.length > 0 && parseInt(campo.tblConfHistoriaseccione.hiseCol) + ultimo > 12) {
                                rowt[(rowt.length - 1)].colSpan = rowt[(rowt.length - 1)].colSpan + (12 - ultimo);
                                row[(row.length - 1)].colSpan = row[(row.length - 1)].colSpan + (12 - ultimo);
                                tabla.push(rowt);
                                tabla.push(row);
                                rowt = null;
                                rowt = [];
                                row = null;
                                row = [];
                                ultimo = 0;
                            }
                            ultimo = parseInt(campo.tblConfHistoriaseccione.hiseCol) + ultimo;
                            let val = '';
                            let valor = campo.tblConfHistoriaseccione.hiseNombre + ': ';
                            pdf.splitTextToSize(valor, parseInt(campo.tblConfHistoriaseccione.hiseCol) * 13).forEach(element => val = val + element + '\n');
                            rowt.push({ content: valor, colSpan: parseInt(campo.tblConfHistoriaseccione.hiseCol), styles: { fontStyle: 'bold' } });

                            let val1 = '';
                            valor = ((campo.tblConfHistoriaseccione.tipodato.comaNombrecorto == 'LISTA') ? `${this.utils.getValues(campo.tblConfHistoriaseccione.hiseValores, campo.cihfValor)} (${campo.cihfValor})` : (campo.cihfValor ? (campo.cihfValor + '') : ''));
                            pdf.splitTextToSize(valor, parseInt(campo.tblConfHistoriaseccione.hiseCol) * 14).forEach(element => val1 = val1 + element + '\n');
                            row.push({ content: valor, colSpan: parseInt(campo.tblConfHistoriaseccione.hiseCol), styles: {} });
                            if (cantidad >= 4) {
                                if (ultimo == 12) {
                                    ultimo = 0;
                                    tabla.push(rowt);
                                    tabla.push(row);
                                    rowt = null;
                                    rowt = [];
                                    row = null;
                                    row = [];
                                } else {
                                    if ((coindex) == cantidad) {
                                        ultimo = 0;
                                        rowt[(rowt.length - 1)].colSpan = rowt[(rowt.length - 1)].colSpan + (12 - rowt[(rowt.length - 1)].colSpan);
                                        row[(row.length - 1)].colSpan = row[(row.length - 1)].colSpan + (12 - row[(row.length - 1)].colSpan);
                                        tabla.push(rowt);
                                        tabla.push(row);
                                        rowt = null;
                                        rowt = [];
                                        row = null;
                                        row = [];
                                    }
                                }

                            } else {
                                if ((coindex) == cantidad) {
                                    ultimo = 0;
                                    tabla.push(rowt);
                                    tabla.push(row);
                                    rowt = null;
                                    rowt = [];
                                    row = null;
                                    row = [];
                                }
                            }
                            if (((coindex) % 9) == 0) {
                                finalY = pdf.previousAutoTable.finalY;
                                pdf.autoTable({
                                    theme:'plain',
                                    styles: { cellPadding: cellPadding, fontSize:fontSize, textColor: [0, 0, 0], overflow: 'linebreak' },
                                     startY: (cindex - 9 == 0) ? (finalY + 5) : finalY, head: (cindex - 9 == 0) ? head : [], body: tabla, showHead: 'firstPage', pageBreak: 'auto'
                                });
                                finalY = pdf.previousAutoTable.finalY;
                                tabla = [];
                            } else {
                                if ((coindex) == seccion.campos.length) {
                                    finalY = pdf.previousAutoTable.finalY;
                                    pdf.autoTable({
                                        theme:'plain',
                                        styles: { cellPadding: cellPadding, fontSize:fontSize, textColor: [0, 0, 0], overflow: 'linebreak' },
                                         startY: finalY, head: [], body: tabla, showHead: 'firstPage', pageBreak: 'auto'
                                    });
                                    finalY = pdf.previousAutoTable.finalY;
                                    tabla = [];
                                }

                            }
                        });
                    }
                }


            }

        });
        finalY = pdf.previousAutoTable.finalY;
        
        let isOdonto = ['ODONTOLOGÍA', 'HIGIENE ORAL', 'ORTODONCIA'];
        let servicio = datosPaciente.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre;
        
        let configServicio = configHistoria.servicios.find(e => e.servicio == servicio);
        if(!configServicio){
            configServicio = configHistoria.servicios.find(e => e.servicio == "DEFAULT");
        }
        if((configServicio.imprimir.verDiagnosticos && configServicio.imprimir.verDiagnosticos == 'Si') || !configServicio.imprimir.verDiagnosticos){
            let d = this.getDiagnosticos(pdf, diagnosticos, true, datosPaciente, finalY);
            pdf = d.pdf;
            finalY = d.finalY;
        }
        if((configServicio.imprimir.verOrdenesRealizadas && configServicio.imprimir.verOrdenesRealizadas == 'Si') || !configServicio.imprimir.verOrdenesRealizadas){
            let or = this.getOrdenesRealizadas(pdf, ordenes, true, datosPaciente, finalY, null);
            pdf = or.pdf;
            finalY = or.finalY;
        }
        if((configServicio.imprimir.verRecetarios && configServicio.imprimir.verRecetarios == 'Si') || !configServicio.imprimir.verRecetarios){
            let r = this.getRecetarios(pdf, recetarios, true, datosPaciente, finalY, null);
            pdf = r.pdf;
            finalY = r.finalY;
        }
        if((configServicio.imprimir.verOrdenes && configServicio.imprimir.verOrdenes == 'Si') || !configServicio.imprimir.verOrdenes){
            let olab = this.getOrdenesLaboratorios(pdf, ordenes, true, datosPaciente, finalY, null);
            pdf = olab.pdf;
            finalY = olab.finalY;
            let oid = this.getOrdenesImagenDiag(pdf, ordenes, true, datosPaciente, finalY, null);
            pdf = oid.pdf;
            finalY = oid.finalY;
           let ose = this.getOrdenesServicios(pdf, ordenes, true, datosPaciente, finalY, null);
            pdf = ose.pdf;
            finalY = ose.finalY;
            let oci = this.getOrdenesCirujia(pdf, ordenes, true, datosPaciente, finalY, null);
            pdf = oci.pdf;
            finalY = oci.finalY;
        }
        
        if((configServicio.imprimir.verRemiciones && configServicio.imprimir.verRemiciones == 'Si') || !configServicio.imprimir.verRemiciones){
            let re = this.getRemisiones(pdf, remisiones, true, datosPaciente, finalY, null);
            pdf = re.pdf;
            finalY = re.finalY;
        }
        if((configServicio.imprimir.verIncapacidades && configServicio.imprimir.verIncapacidades == 'Si') || !configServicio.imprimir.verIncapacidades){
            let inc = this.getIncapacidades(pdf, incapacidades, true, datosPaciente, finalY, null);
            pdf = inc.pdf;
            finalY = inc.finalY;
        }
        if((configServicio.imprimir.verCertificados && configServicio.imprimir.verCertificados == 'Si') || !configServicio.imprimir.verCertificados){
            let ce = this.getCertificados(pdf, certificados, true, datosPaciente, finalY, null);
            pdf = ce.pdf;
            finalY = ce.finalY;
        }
        
        if (!isOdonto.some(e =>  servicio.indexOf(e) > -1) && vacunas.length > 0) {
            let va = this.getVacunas(pdf, vacunas, true, datosPaciente, finalY, null);
            pdf = va.pdf;
            finalY = va.finalY;
        }

        pdf.setFontSize(7);
        pdf.setFont(undefined, "bold");
        let pageHeight = pdf.internal.pageSize.height;
        if ((pageHeight - finalY) < 40) {
            pdf.addPage();
            finalY = 0;
        }
        if (secciones.some(e => e.hiseCodigo == 'UTSG_S15')) {
            let camposfirmas = secciones.find(e => e.hiseCodigo == 'UTSG_S15');
            let pageHeight = pdf.internal.pageSize.height;
            if ((pageHeight - (finalY + 30)) < 60) {
                pdf.addPage();
                finalY = 0;
            }
            if (camposfirmas.campos.some(e => e.tblConfHistoriaseccione.hiseCodigo == 'UTSG_S15_1')) {
                let firma = camposfirmas.campos.find(e => e.tblConfHistoriaseccione.hiseCodigo == 'UTSG_S15_1');
                try {
                    let img = new Image();
                    img.src = '/firmas/union/' + firma.cihfValor + '.png'
                    pdf.addImage(img, x + 20, finalY + 5, 40, 20);
                } catch (error) {
                    console.log(error);
                }
                pdf.text(this.utils.getValues(firma.tblConfHistoriaseccione.hiseValores, firma.cihfValor), x + 20, finalY + 30);
                pdf.text('FISIOTERAPEUTA', x + 20, finalY + 34);
            }
            if (camposfirmas.campos.some(e => e.tblConfHistoriaseccione.hiseCodigo == 'UTSG_S15_2')) {
                let firma = camposfirmas.campos.find(e => e.tblConfHistoriaseccione.hiseCodigo == 'UTSG_S15_2');
                try {
                    let img = new Image();
                    img.src = '/firmas/union/' + firma.cihfValor + '.png'
                    pdf.addImage(img, x + 120, finalY + 5, 40, 20);
                } catch (error) {
                    console.log(error);
                }
                pdf.text(this.utils.getValues(firma.tblConfHistoriaseccione.hiseValores, firma.cihfValor), x + 120, finalY + 30);
                pdf.text('JEFE DE ENFERMERÍA', x + 120, finalY + 34);
            }
            if ((pageHeight - (finalY + 60)) < 60) {
                pdf.addPage();
                finalY = 0;
            }
            if (camposfirmas.campos.some(e => e.tblConfHistoriaseccione.hiseCodigo == 'UTSG_S15_3')) {
                let firma = camposfirmas.campos.find(e => e.tblConfHistoriaseccione.hiseCodigo == 'UTSG_S15_3');
                try {
                    let img = new Image();
                    img.src = '/firmas/union/' + firma.cihfValor + '.png'
                    pdf.addImage(img, x + 20, finalY + 35, 40, 20);
                } catch (error) {
                    console.log(error);
                }
                pdf.text(this.utils.getValues(firma.tblConfHistoriaseccione.hiseValores, firma.cihfValor), x + 20, finalY + 60);
                pdf.text('PSICÓLOGA', x + 20, finalY + 64);
            }
            if (camposfirmas.campos.some(e => e.tblConfHistoriaseccione.hiseCodigo == 'UTSG_S15_4')) {
                let firma = camposfirmas.campos.find(e => e.tblConfHistoriaseccione.hiseCodigo == 'UTSG_S15_4');
                try {
                    let img = new Image();
                    img.src = '/firmas/union/' + firma.cihfValor + '.png'
                    pdf.addImage(img, x + 120, finalY + 35, 40, 20);
                } catch (error) {
                    console.log(error);
                }
                pdf.text(this.utils.getValues(firma.tblConfHistoriaseccione.hiseValores, firma.cihfValor), x + 120, finalY + 60);
                pdf.text('AUXILIAR DE ENFERMERÍA', x + 120, finalY + 64);
            }
            if (camposfirmas.campos.some(e => e.tblConfHistoriaseccione.hiseCodigo == 'UTSG_S15_4')) {
                let firma = camposfirmas.campos.find(e => e.tblConfHistoriaseccione.hiseCodigo == 'UTSG_S15_4');
                try {
                    let img = new Image();
                    img.src = '/firmas/union/' + firma.cihfValor + '.png'
                    pdf.addImage(img, x + 120, finalY + 35, 40, 20);
                } catch (error) {
                    console.log(error);
                }
                pdf.text(this.utils.getValues(firma.tblConfHistoriaseccione.hiseValores, firma.cihfValor), x + 120, finalY + 60);
                pdf.text('AUXILIAR DE ENFERMERÍA', x + 120, finalY + 64);
            }
            if (camposfirmas.campos.some(e => e.tblConfHistoriaseccione.hiseCodigo == 'UTSG_S15_5')) {
                let firma = camposfirmas.campos.find(e => e.tblConfHistoriaseccione.hiseCodigo == 'UTSG_S15_5');
                try {
                    let img = new Image();
                    img.src = '/firmas/union/' + firma.cihfValor + '.png'
                    pdf.addImage(img, x + 120, finalY + 35, 40, 20);
                } catch (error) {
                    console.log(error);
                }
                pdf.text(this.utils.getValues(firma.tblConfHistoriaseccione.hiseValores, firma.cihfValor), x + 120, finalY + 60);
                pdf.text('NUTRICIONISTA', x + 120, finalY + 64);
            }
            if (camposfirmas.campos.some(e => e.tblConfHistoriaseccione.hiseCodigo == 'UTSG_S15_6')) {
                let firma = camposfirmas.campos.find(e => e.tblConfHistoriaseccione.hiseCodigo == 'UTSG_S15_6');
                try {
                    let img = new Image();
                    img.src = '/firmas/union/' + firma.cihfValor + '.png'
                    pdf.addImage(img, x + 120, finalY + 35, 40, 20);
                } catch (error) {
                    console.log(error);
                }
                pdf.text(this.utils.getValues(firma.tblConfHistoriaseccione.hiseValores, firma.cihfValor), x + 120, finalY + 60);
                pdf.text('PEDIATRA', x + 120, finalY + 64);
            }
            if (camposfirmas.campos.some(e => e.tblConfHistoriaseccione.hiseCodigo == 'UTSG_S15_7')) {
                let firma = camposfirmas.campos.find(e => e.tblConfHistoriaseccione.hiseCodigo == 'UTSG_S15_7');
                try {
                    let img = new Image();
                    img.src = '/firmas/union/' + firma.cihfValor + '.png'
                    pdf.addImage(img, x + 120, finalY + 35, 40, 20);
                } catch (error) {
                    console.log(error);
                }
                pdf.text(this.utils.getValues(firma.tblConfHistoriaseccione.hiseValores, firma.cihfValor), x + 120, finalY + 60);
                pdf.text('GINECOLOGO', x + 120, finalY + 64);
            }
            if ((pageHeight - (finalY + 103)) < 60) {
                pdf.addPage();
                finalY = 0;
            }
            if (datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != null && datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != '') {
                try {
                    let img = new Image();
                    img.src = '/' + datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma
                    pdf.addImage(img, x + 75, finalY + 75, 40, 20);
                } catch (error) {
                    console.log(error);
                }
            }

            pdf.text(
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + ' ' +
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1, x + 75, finalY + 95);
            pdf.text(datosPaciente.tblConfPersonasempleados[0].cargo.comaNombrelargo, x + 75, finalY + 99);
            if (datosPaciente.tblConfPersonasempleados[0].coprPieFirma) {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].coprPieFirma, x + 75, finalY + 103);
            } else {
                pdf.text(datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto + ' Nº ' +
                    datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, x + 75, finalY + 103);
            }
        } else {
            this.setFirmas(datosPaciente, pdf , x , finalY)
        }
        
        if (isOdonto.some(e =>  servicio.indexOf(e) > -1)) {
            pdf.addPage();
            finalY = 20;
            pdf.setFontSize(10);
            pdf.text(100, finalY, "ANEXOS", "center");
            if (imagenesodontologia.imgcanvasplaca) {
                pdf.text(100, finalY + 5, "REGISTRO DE PLACA", "center");
                let c = await this.cargarplaca(pdf, imagenesodontologia.imgcanvasplaca, finalY, x);
                pdf = c.pdf;
                finalY = c.finalY;
            }

            if (imagenesodontologia.imgprimerodontogramacanvas) {
                pdf.text(100, finalY + 15, "PRIMER ODONTOGRAMA", "center");
                let op = await this.cargarodontograma(pdf, imagenesodontologia.imgprimerodontogramacanvas, finalY + 5, x);
                pdf = op.pdf;
                finalY = op.finalY;
            }
            if (imagenesodontologia.imgodontogramacanvas) {
                pdf.text(100, finalY + 15, "ODONTOGRAMA ACTUAL", "center");
                let o = await this.cargarodontograma(pdf, imagenesodontologia.imgodontogramacanvas, finalY + 5, x);
                pdf = o.pdf;
                finalY = o.finalY;
            }
        }
        let edad = (new Date(datosPaciente.tblCitaFacturaciones[0].cifaFecha.replace(/-+/g, '/')).getTime() - new Date(datosPaciente.tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000;
        if((configServicio.imprimir.verGraficasJuventud && configServicio.imprimir.verGraficasJuventud == "Si") || !configServicio.imprimir.verGraficasJuventud){
            if (!['CONSULTA ESP. POR NEUROLOGÍA Y NEUROCIRUGÍA','CONSULTA ESP. POR MEDICINA INTERNA'].some(e => e == servicio) && !isOdonto.some(e =>  servicio.indexOf(e) > -1)) {
                if (edad <= 6569) {
                    pesos = pesos.filter(e => new Date(e.fecha.replace(/-+/g, '/')).getTime() <= new Date(datosPaciente.tblCitaFacturaciones[0].cifaFecha.replace(/-+/g, '/')).getTime());
                    pdf.addPage();                                          
                    finalY = 20;
                    pdf.text(100, finalY, "ANEXOS", "center");
                    
                    if (edad <= 3650) {
                            let pesoEdadCondicion = edad < 731 ? (e)=> e.data.mes <= 24 :(e)=> e.data.mes >= 24;
                            let semanaomes = edad < 92 ? "semana":"mes";
                            let pesoEdadCodigo = `PESO_EDAD_${sexo=='M'?'BOYS':'GIRLS'}_${edad < 92 ? "_0_TO_13S":edad < 731 ?"0_TO_5":"5_TO_10"}` ;
                            let tituloPesoEdad=edad < 92 ? "0 A 13 semanas":edad < 731 ? "0 A 2 AÑOS":edad < 3650 ?"2 A 5 AÑOS":"5 A 10 AÑOS";
                            let dataPeso02 = dataSource.filter(e => e.dataValues.cogpCodigo == pesoEdadCodigo && pesoEdadCondicion(e)).map(item => item.data);
                            let optionsPesoEdad = {
                                titleX:"EDAD(MES)",
                                titleY:"PESO(KG)",
                                dataSource:dataPeso02,
                                data:pesos,
                                finalY:finalY,
                                x:x,
                                lines:[
                                    {label:"3",color:"black",x:semanaomes,y:"3"},
                                    {label:"2",color:"red",x:semanaomes,y:"2"},
                                    {label:"1",color:"yellow",x:semanaomes,y:"1"},
                                    {label:"0",color:"green",x:semanaomes,y:"0"},
                                    {label:"-1",color:"yellow",x:semanaomes,y:"-1"},
                                    {label:"-2",color:"red",x:semanaomes,y:"-2"},
                                    {label:"-3",color:"black",x:semanaomes,y:"-3"},
                                    {label:"peso",color:"purple",x:semanaomes,y:"peso",lineadata:true},
                                ]
                            }
                            pdf.text(100, finalY + 5, "PESO PARA LA EDAD DE "+tituloPesoEdad, "center");
                            let GraficaPesoPorEdad = await this.graficas.getGraficaLines(pdf, optionsPesoEdad);
                            pdf = GraficaPesoPorEdad.pdf;
                            finalY = GraficaPesoPorEdad.finalY;
                    }
                    if (edad <= 1825) {
                        let dataPesoAltura05 = dataSource.filter(e => e.dataValues.cogpCodigo == `PESO_TALLA_${sexo=='M'?'BOYS':'GIRLS'}_0_TO_2` || e.dataValues.cogpCodigo == `PESO_ALTURA_${sexo=='M'?'BOYS':'GIRLS'}_2_TO_5`).map(item => item.data);
                            let options = {
                                titleX:"TALLA/ALTURA(CM)",
                                titleY:"PESO(KG)",
                                dataSource:dataPesoAltura05,
                                data:pesos,
                                finalY:finalY,
                                x:x,
                                lines:[
                                    {label:"3",color:"black",x:"altura",y:"3"},
                                    {label:"2",color:"red",x:"altura",y:"2"},
                                    {label:"1",color:"yellow",x:"altura",y:"1"},
                                    {label:"0",color:"green",x:"altura",y:"0"},
                                    {label:"-1",color:"yellow",x:"altura",y:"-1"},
                                    {label:"-2",color:"red",x:"altura",y:"-2"},
                                    {label:"-3",color:"black",x:"altura",y:"-3"},
                                    {label:"peso",color:"purple",x:"talla",y:"peso",lineadata:true},
                                ]
                            }
                        pdf.text(100, finalY + 5, "PESO PARA LA TALLA/ALTURA DE 0 A 5 AÑOS", "center");
                        let GraficaPesoPorAltura = await this.graficas.getGraficaLines(pdf, options);
                        pdf = GraficaPesoPorAltura.pdf;
                        finalY = GraficaPesoPorAltura.finalY;
                        pdf.addPage();
                        finalY = 20;
                        
                        let circuCondicion = edad < 731 ? (e)=> e.data.mes <= 24 :(e)=> e.data.mes <= 60;
                        let semanaomes = edad < 92 ? "semana":"mes";
                        let circuCodigo = `CIRCUFERENCIA_EDAD_${sexo=='M'?'BOYS':'GIRLS'}_${edad < 92 ? "_0_TO_13S":"0_TO_5"}` ;
                        let tituloCircu=edad < 92 ? "0 A 13 semanas":edad < 731 ? "0 A 2 AÑOS":"0 A 5 AÑOS";
                        let dataCircu = dataSource.filter(e => e.dataValues.cogpCodigo == circuCodigo && circuCondicion(e)).map(item => item.data);
                        let optionsCircu = {
                            titleX:"EDAD(MES)",
                            titleY:"CIRCUFERENCIA CABEZA(CM)",
                            dataSource:dataCircu,
                            data:pesos,
                            finalY:finalY,
                            x:x,
                            lines:[
                                {label:"3",color:"black",x:semanaomes,y:"3"},
                                {label:"2",color:"red",x:semanaomes,y:"2"},
                                {label:"1",color:"yellow",x:semanaomes,y:"1"},
                                {label:"0",color:"green",x:semanaomes,y:"0"},
                                {label:"-1",color:"yellow",x:semanaomes,y:"-1"},
                                {label:"-2",color:"red",x:semanaomes,y:"-2"},
                                {label:"-3",color:"black",x:semanaomes,y:"-3"},
                                {label:"circuferencia cabeza",color:"purple",x:semanaomes,y:"perimetrocefalico",lineadata:true},
                            ]
                        }
                        pdf.text(100, finalY + 5, "CIRCUFERENCIA DE LA CABEZA PARA LA EDAD DE "+tituloCircu, "center");
                        let GraficaCircuferenciaCabezaPorEdad = await this.graficas.getGraficaLines(pdf, optionsCircu);
                        pdf = GraficaCircuferenciaCabezaPorEdad.pdf;
                        finalY = GraficaCircuferenciaCabezaPorEdad.finalY;
                    }
                    
                    let tallaEdadCodigo = `TALLA_EDAD_${sexo=='M'?'BOYS':'GIRLS'}_${edad < 731 ? "0_TO_2":edad < 1825 ?"2_TO_5":"5_TO_19"}` ;
                    let tituloTallaEdad=edad < 731 ? "0 A 2 AÑOS":edad < 1825 ?"2 A 5 AÑOS":"5 A 19 AÑOS";
                    let dataTallaEdad02 = dataSource.filter(e => e.dataValues.cogpCodigo == tallaEdadCodigo).map(item => item.data);
                    let optionsTallaEdad = {
                        titleX:"EDAD(MES)",
                        titleY:"TALLA(CM)",
                        dataSource:dataTallaEdad02,
                        data:pesos,
                        finalY:finalY,
                        x:x,
                        lines:[
                            {label:"3",color:"black",x:"mes",y:"3"},
                            {label:"2",color:"red",x:"mes",y:"2"},
                            {label:"1",color:"yellow",x:"mes",y:"1"},
                            {label:"0",color:"green",x:"mes",y:"0"},
                            {label:"-1",color:"yellow",x:"mes",y:"-1"},
                            {label:"-2",color:"red",x:"mes",y:"-2"},
                            {label:"-3",color:"black",x:"mes",y:"-3"},
                            {label:"talla",color:"purple",x:"mes",y:"talla",lineadata:true},
                        ]
                    }
                    pdf.text(100, finalY + 5, "TALLA PARA LA EDAD DE "+tituloTallaEdad, "center");
                    let GraficaPesoPorLongitud = await this.graficas.getGraficaLines(pdf, optionsTallaEdad);
                    pdf = GraficaPesoPorLongitud.pdf;
                    finalY = GraficaPesoPorLongitud.finalY;
                    
                    let tituloIMC = edad < 92 ? "DE 0 A 13 SEMANAS":edad < 731 ? "DE 0 A 2 AÑOS":edad < 1825 ?"DE 2 A 5 AÑOS":"DE 5 A 19 AÑOS";
                    let semanaomesCodigo = `BMI_EDAD_${sexo=='M'?'BOYS':'GIRLS'}_${edad < 92 ? "0_TO_13S":edad < 731 ? "0_TO_2":edad < 1825 ?"2_TO_5":"5_TO_19"}` ;
                    let dataIMCEdad = dataSource.filter(e => e.dataValues.cogpCodigo == semanaomesCodigo).map(item => item.data);
                    let semanaomesImc = edad < 92 ? "semana":"mes";
                    let optionsImc = {
                        titleX:"EDAD(MES)",
                        titleY:"IMC(KG/M^2)",
                        dataSource:dataIMCEdad,
                        data:pesos,
                        finalY:finalY,
                        x:x,
                        lines:[
                            {label:"3",color:"black",x:semanaomesImc,y:"3"},
                            {label:"2",color:"red",x:semanaomesImc,y:"2"},
                            {label:"1",color:"yellow",x:semanaomesImc,y:"1"},
                            {label:"0",color:"green",x:semanaomesImc,y:"0"},
                            {label:"-1",color:"yellow",x:semanaomesImc,y:"-1"},
                            {label:"-2",color:"red",x:semanaomesImc,y:"-2"},
                            {label:"-3",color:"black",x:semanaomesImc,y:"-3"},
                            {label:"imc",color:"purple",x:semanaomesImc,y:"imc",lineadata:true},
                        ]
                    }
                    pdf.text(100, finalY + 5, "IMC PARA LA EDAD "+tituloIMC, "center");
                    let GraficaBMIPorEdad =  await this.graficas.getGraficaLines(pdf, optionsImc);
                    pdf = GraficaBMIPorEdad.pdf;
                    finalY = GraficaBMIPorEdad.finalY;
                } 
            }
        }
        if (['ATENCIÓN PRENATAL'].some(e => e == servicio)) {
            pdf.addPage();
            finalY = 20;
            let dataIMCGestacion = dataSource.filter(e => e.dataValues.cogpCodigo == "IMC_GESTACIONAL").map(item => item.data);
            let optionsIMCGestacion = {
                titleX:"SEMANAS DE GESTACION",
                titleY:"IMC(KG/M^2)",
                dataSource:dataIMCGestacion,
                data:pesos.filter(e => e.SERVICIO == 'ATENCIÓN PRENATAL'),
                finalY:finalY,
                x:x,
                lines:[
                    
                    {label:"OBESIDAD",color:"orange",x:"semana",y:"OBESIDAD",tipo:"area"},
                    {label:"SOBREPESO",color:"yellow",x:"semana",y:"SOBREPESO",tipo:"area"},
                    {label:"ADECUADO",color:"green",x:"semana",y:"ADECUADO",tipo:"area"},
                    {label:"BAJO PESO",color:"pink",x:"semana",y:"BAJO PESO",tipo:"area"},
                    {label:"imc",color:"purple",x:"semana",y:"imc",lineadata:true},
                ]
            }
            pdf.text(100, finalY + 5, "IMC vs Semanas de gestacion", "center");
            let GraficaIMCPorGestacion = await this.graficas.getGraficaArea(pdf, optionsIMCGestacion);
            pdf = GraficaIMCPorGestacion.pdf;
            finalY = GraficaIMCPorGestacion.finalY;

            let dataAlturaUterina = dataSource.filter(e => e.dataValues.cogpCodigo == 'ALTURA_UTERINA_GIRLS_24_TO_42S').map(item => item.data);
            let semanaomes =  "semana";
            let options = {
                titleX:"EDAD GESTACIONAL(SEMANAS)",
                titleY:"ALTURA UTERINA(CM)",
                dataSource:dataAlturaUterina,
                data:pesos.filter(e => e.SERVICIO == 'ATENCIÓN PRENATAL'),
                finalY:finalY,
                x:x,
                lines:[
                    {label:"p90",color:"red",x:semanaomes,y:"p90"},
                    {label:"p50",color:"black",x:semanaomes,y:"p50"},
                    {label:"p10",color:"blue",x:semanaomes,y:"p10"},
                    {label:"alturau",color:"purple",x:semanaomes,y:"alturau",lineadata:true},
                ]
            }
            pdf.text(100, finalY + 5, "ALTURA UTERINA", "center");
            let GraficaAlturaUterina =  await this.graficas.getGraficaLines(pdf, options);
            pdf = GraficaAlturaUterina.pdf;
            finalY = GraficaAlturaUterina.finalY;
        }
        if((configServicio.imprimir.verRecetarios && configServicio.imprimir.verRecetarios == 'Si') || !configServicio.imprimir.verRecetarios){
            if((configServicio.imprimir.ordenesPorPagina && configServicio.imprimir.ordenesPorPagina == 'Si' && recetarios.length > 0)){
                pdf.addPage();
                x = 14;
                finalY = 2;
                let r = this.getRecetarios(pdf, recetarios, true, datosPaciente, finalY, diagnosticos,false,true);
                pdf = r.pdf;
                finalY = r.finalY;
            }
        }
        if((configServicio.imprimir.verOrdenes && configServicio.imprimir.verOrdenes == 'Si') || !configServicio.imprimir.verOrdenes){
            let porPagina = false;
            if((configServicio.imprimir.ordenesPorPagina && configServicio.imprimir.ordenesPorPagina == 'Si' && ordenes.length > 0 && ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_LAB').length)){
                pdf.addPage();
                finalY = 2;
                porPagina = true;
                let olab = this.getOrdenesLaboratorios(pdf, ordenes, true, datosPaciente, finalY, diagnosticos,false,porPagina);
                pdf = olab.pdf;
                finalY = olab.finalY;
                porPagina = false;
            }
           
            if((configServicio.imprimir.ordenesPorPagina && configServicio.imprimir.ordenesPorPagina == 'Si' && ordenes.length > 0 && ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_IMG').length)){
                pdf.addPage();
                finalY = 2;
                porPagina = true;
                let oid = this.getOrdenesImagenDiag(pdf, ordenes, true, datosPaciente, finalY, diagnosticos,false,porPagina);
                pdf = oid.pdf;
                finalY = oid.finalY;
                porPagina = false;
            }
            
            if((configServicio.imprimir.ordenesPorPagina && configServicio.imprimir.ordenesPorPagina == 'Si' && ordenes.length > 0 && ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_SER').length)){
                pdf.addPage();
                finalY = 2;
                porPagina = true;
                let ose = this.getOrdenesServicios(pdf, ordenes, true, datosPaciente, finalY, diagnosticos,false,porPagina);
                pdf = ose.pdf;
                finalY = ose.finalY;
                porPagina = false;
            }
            
            if((configServicio.imprimir.ordenesPorPagina && configServicio.imprimir.ordenesPorPagina == 'Si' && ordenes.length > 0 && ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_CIR').length)){
                pdf.addPage();
                x = 14;
                finalY = 2;
                porPagina = true;
                let oci = this.getOrdenesCirujia(pdf, ordenes, true, datosPaciente, finalY, diagnosticos,false,porPagina);
                pdf = oci.pdf;
                finalY = oci.finalY;
            }
        }
        
        if((configServicio.imprimir.verRemiciones && configServicio.imprimir.verRemiciones == 'Si') || !configServicio.imprimir.verRemiciones){
            let porPagina = false;
            if((configServicio.imprimir.ordenesPorPagina && configServicio.imprimir.ordenesPorPagina == 'Si' && remisiones.length > 0)){
                pdf.addPage();
                x = 14;
                finalY = 2;
                porPagina = true;
                let re = this.getRemisiones(pdf, remisiones, true, datosPaciente, finalY, diagnosticos,false,porPagina);
                pdf = re.pdf;
                finalY = re.finalY;
            }
           
        }
        if((configServicio.imprimir.verIncapacidades && configServicio.imprimir.verIncapacidades == 'Si') || !configServicio.imprimir.verIncapacidades){
            let porPagina = false;
            if((configServicio.imprimir.ordenesPorPagina && configServicio.imprimir.ordenesPorPagina == 'Si' && incapacidades.length > 0)){
                pdf.addPage();
                x = 14;
                finalY = 2;
                porPagina = true;
                let inc = this.getIncapacidades(pdf, incapacidades, true, datosPaciente, finalY, diagnosticos,false,porPagina);
                pdf = inc.pdf;
                finalY = inc.finalY;
            }
        }
        if((configServicio.imprimir.verCertificados && configServicio.imprimir.verCertificados == 'Si') || !configServicio.imprimir.verCertificados){
            let porPagina = false;
            if((configServicio.imprimir.ordenesPorPagina && configServicio.imprimir.ordenesPorPagina == 'Si' && certificados.length > 0)){
                pdf.addPage();
                x = 14;
                finalY = 2;
                porPagina = true;
                let ce = this.getCertificados(pdf, certificados, true, datosPaciente, finalY, diagnosticos,false,porPagina);
                pdf = ce.pdf;
                finalY = ce.finalY;
            }
        }
        
        if (!isOdonto.some(e =>  servicio.indexOf(e) > -1) && vacunas.length > 0) {
            let porPagina = false;
            if((configServicio.imprimir.ordenesPorPagina && configServicio.imprimir.ordenesPorPagina == 'Si')){
                pdf.addPage();
                x = 14;
                finalY = 2;
                porPagina = true;
                let va = this.getVacunas(pdf, vacunas, true, datosPaciente, finalY, diagnosticos,false,porPagina);
                pdf = va.pdf;
                finalY = va.finalY;
            }
        }
        if (cuerpo) {
            pdf.addPage();
            return { pdf, finalY };
        } else {
            pdf.setFontSize(7);
            var pageCount = pdf.internal.getNumberOfPages(); // pageCount es el nº de páginas
            for (let i = 0; i < pageCount; i++) { // código que se repite para cada página (bucle)
                pdf.setPage(i);
                pdf.text(190, 285, "Página " + pdf.internal.getCurrentPageInfo().pageNumber + " de " + pageCount);
                pdf.setFontSize(7);
                //pdf.setFont("normal");
                pdf.text('IPS Tools (HIS - Hospital Information System)', x, 279 + 8);
                pdf.text('TEL: 3168775054', x, 279 + 11);
                pdf.text('®'+new Date().getFullYear()+'Powered by www.jasoftwaresas.com', x, 279 + 14);
            }
                if (email) {
                    return pdf.output('blob');
                } else {
                    if (navigator.userAgent.indexOf('irefox') > -1) {

                        pdf.output("dataurlnewwindow");
                    } else {
                        window.open(pdf.output('bloburl'), "_blank");
                    }
                }
        }
    }
    getPrevista(pdf, seccion) {

        let x = 14;
        let finalY = 20;
        pdf = this.utils.getCabecera(pdf, x, finalY);
        pdf.line(x, finalY + 35, 195, finalY + 35);
        pdf.setFontSize(10);
        pdf.setFont(undefined, "bold");
        finalY = finalY + 45;
        let head = [
            [{
                content: seccion.hiseNombre,
                colSpan: 2,
                styles: { halign: 'center', valing: 'center' },
            }]
        ];
        let tabla = [];
        let row = [];

        let ultimo = 0;

        if (seccion.campos.length > 0) {
            seccion.campos.sort((a, b) => a.hiseOrden - b.hiseOrden);
            seccion.campos.forEach((campo, index) => {
                let val1 = '';
                pdf.splitTextToSize(campo.hiseNombre + '', 30).forEach(element => val1 = val1 + element + '\n');
                row.push({ content: val1, styles: { halign: 'center', valing: 'center', fontStyle: 'bold' } });
                let val = '';
                pdf.splitTextToSize('sdfsdfsdfsdfsdfsdfsdfsdfsdfsdfssdfsdf', 100).forEach(element => val = val + element + '\n');
                if (seccion.hiseCalculado) {
                    //total = total + parseInt(item.cihfValor);

                    if (campo.tipodato.comaNombrecorto == 'SI/NO' || campo.tipodato.comaNombrecorto == 'LISTA') {
                        row.push({ content: `SI`, });
                    } else {

                        row.push({ content: val, styles: {}, });
                    }
                } else {
                    row.push(val);
                }
                tabla.push(row);
                ultimo = ultimo + 1;
                row = null;
                row = [];
                if (((index + 1) % 10) == 0) {
                    // finalY = pdf.previousAutoTable.finalY;


                    pdf.autoTable({
                        styles: { cellPadding: 0.5, fontSize: 8, textColor: [0, 0, 0], overflow: 'ellipsize' },
                        didParseCell: (table) => {

                            if (table.section === 'head') {
                                table.cell.styles.fillColor = '#85D5FA';
                            }
                        },
                        startY: (((index + 1) - 10) == 0) ? (finalY + 5) : finalY, head: (((index + 1) - 10) == 0) ? head : [], body: tabla, showHead: 'firstPage', pageBreak: 'auto'
                    });
                    finalY = pdf.previousAutoTable.finalY;
                    tabla = [];
                } else {
                    if ((index + 1) == seccion.campos.length && seccion.campos.length < 10) {

                        pdf.autoTable({
                            styles: { cellPadding: 0.5, fontSize: 8, textColor: [0, 0, 0], overflow: 'ellipsize' },
                            startY: finalY + 5, head: head, body: tabla, showHead: 'firstPage', pageBreak: 'auto'
                        });
                        finalY = pdf.previousAutoTable.finalY;
                        tabla = [];
                    } else {
                        if ((index + 1) == seccion.campos.length) {

                            pdf.autoTable({
                                styles: { cellPadding: 0.5, fontSize: 8, textColor: [0, 0, 0], overflow: 'ellipsize' },
                                startY: finalY, head: [], body: tabla, showHead: 'firstPage', pageBreak: 'auto'
                            });
                            finalY = pdf.previousAutoTable.finalY;
                            tabla = [];
                        }
                    }

                }


            });
        }
        row = null;
        row = [];

        pdf.setFontSize(7);
        var pageCount = pdf.internal.getNumberOfPages(); // pageCount es el nº de páginas
        for (let i = 0; i < pageCount; i++) { // código que se repite para cada página (bucle)
            pdf.setPage(i);
            pdf.text(190, 285, "Página " + pdf.internal.getCurrentPageInfo().pageNumber + " de " + pageCount);
        }

        if (navigator.userAgent.indexOf('irefox') > -1) {

            pdf.output("dataurlnewwindow");
        } else {
            window.open(pdf.output('bloburl'), "_blank");
        }

        // window.open(pdf.output('bloburl'), '_blank');
        // window.open(pdf.output('bloburl'), '_blank');
    }
    getLaboratorio(pdf, datosPaciente, Historia, secciones, diagnosticos, qr = null, firma = '') {
        let datos = this.getDatosPaciente(datosPaciente,'laboratorio'); 

        let x = 14 + 26;
        let finalY = 5;
        pdf = this.utils.getCabecera2(pdf, x - 26, finalY, qr ? 'LAB' : null);
        if (qr) {
            pdf.addImage(qr, x + 460, finalY, 80, 80);
        }
        pdf.line(x, finalY + 100, 572, finalY + 100);
        pdf.setFontSize(8);
        pdf.setFont(undefined, "bold");
        pdf.setFont('times');
        pdf.autoTable({
            theme:'plain',
            styles: { cellPadding: 0.5, fontSize: 8 },
            startY: finalY + 110,
            didParseCell: (table) => {
              
                if (!(table.row.index % 2)) {
                    table.cell.styles.fontStyle = 'bold'
                }
                table.cell.styles.textColor = [0, 0, 0]

            },
            showHead: 'firstPage',
            head: [
                [{
                    content: 'DATOS DEL PACIENTE',
                    colSpan: 4,
                    styles: { halign: 'center', valing: 'center',lineColor: [0, 0, 0],lineWidth: 0.2  },
                }]
            ],
            body: datos
        });
        datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes.sort((a, b) => { ((a.tblConfCups[0].area) ? a.tblConfCups[0].area.comaNombrelargo : '') < ((b.tblConfCups[0].area) ? b.tblConfCups[0].area.comaNombrelargo : ''); return -1 });
        datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes.forEach((orden) => {
            let cup = pdf.splitTextToSize('CODIGO: ' + orden.tblConfCups[0].cocuCups + ' - EXAMEN: ' + orden.tblConfCups[0].cocuNombre, 160);
            let head = [
                [{
                    content: 'AREA: ' + ((orden.tblConfCups[0].area) ? orden.tblConfCups[0].area.comaNombrelargo : ''),
                    colSpan: 4,
                    styles: { halign: 'left', valing: 'center', textColor: [0, 0, 0],lineColor: [0, 0, 0],lineWidth: 0.2  },
                }],
                [{
                    content: cup,
                    colSpan: 4,
                    styles: { halign: 'left', valing: 'center', textColor: [0, 0, 0],lineColor: [0, 0, 0],lineWidth: 0.2  },
                }],
                [{
                    content: 'FECHA TOMA:' + orden.ciorFechatoma + ' FECHA PROCEDIMIENTO:' + orden.ciorFechaprocedimiento,
                    colSpan: 4,
                    styles: { halign: 'left', textColor: [0, 0, 0] ,lineColor: [0, 0, 0],lineWidth: 0.2 },
                }],
                [{
                    content: 'VARIABLE',
                    //colSpan: (seccion.foseHistorico) ? 3 : 2,
                    styles: { halign: 'center', valing: 'center', textColor: [0, 0, 0] ,lineColor: [0, 0, 0],lineWidth: 0.2 },
                },
                {
                    content: 'RESULTADO',
                    //colSpan: (seccion.foseHistorico) ? 3 : 2,
                    styles: { halign: 'center', valing: 'center', textColor: [0, 0, 0],lineColor: [0, 0, 0],lineWidth: 0.2  },
                }, {
                    content: '',
                    //colSpan: (seccion.foseHistorico) ? 3 : 2,
                    styles: { halign: 'center', valing: 'center', textColor: [0, 0, 0] ,lineColor: [0, 0, 0],lineWidth: 0.2 },
                }, {
                    content: '',
                    //colSpan: (seccion.foseHistorico) ? 3 : 2,
                    styles: { halign: 'center', valing: 'center', textColor: [0, 0, 0],lineColor: [0, 0, 0],lineWidth: 0.2  },
                }]
            ];
            secciones.find(e => e.ciorId == orden.ciorId).secciones.forEach((seccion) => {

                let tabla = [];
                let row = [];

                let total = 0;
                if (seccion.campos.length > 0) {
                    seccion.campos.sort((a, b) => a.foseOrden - b.foseOrden);
                    seccion.campos.forEach((campo) => {
                        if (Historia.find(e => e.ciorId == orden.ciorId).Historia.find(e => e.foseId == campo.foseId) != undefined) {
                            let item = Historia.find(e => e.ciorId == orden.ciorId).Historia.find(e => e.foseId == campo.foseId);
                            row.push({ content: campo.foseNombre, styles: { halign: 'left', valing: 'center', fontStyle: 'bold' } });
                            if (seccion.foseCalculado) {
                                total = total + parseInt(item.ciffValor);
                                if (campo.tipodato.comaNombrecorto == 'SI/NO' || campo.tipodato.comaNombrecorto == 'LISTA') {
                                    row.push(`${this.utils.getValues(campo.foseValores, item.ciffValor)} (${item.ciffValor})`);
                                } else {
                                    row.push(item.ciffValor);
                                }
                            } else {
                                row.push(item.ciffValor);
                            }
                            row.push(orden.tblConfCups[0].cocuUnidad);
                            if (campo.foseDescripcion) {
                                row.push(campo.foseDescripcion);
                            } else {
                                row.push(orden.tblConfCups[0].cocuReferencia);
                            }

                            tabla.push(row);
                            row = null;
                            row = [];
                        }
                    });
                }

                row = null;
                row = [];


                finalY = pdf.previousAutoTable.finalY;


                pdf.autoTable({
                    theme:'plain',
                    styles: { cellPadding: 0.5, fontSize: 8, textColor: [0, 0, 0], overflow: 'linebreak' },
                    startY: finalY + 10, head: head, body: tabla, showHead: 'firstPage', pageBreak: 'auto'
                });
                finalY = pdf.previousAutoTable.finalY;
                tabla = [];

            })
        });

        finalY = pdf.previousAutoTable.finalY;
        let d = this.getDiagnosticos(pdf, diagnosticos, true, datosPaciente, finalY);
        pdf = d.pdf;
        finalY = d.finalY;

        pdf.setFontSize(9);
        pdf.setFont(undefined, "bold");
        //if (Historia[0].Historia[0].empleado && Historia[0].Historia[0].empleado.tblConfPersonasnaturale.tblConfPersona.copeFirma != null) {

        try {
            let img = new Image();
            img.src = firma
            pdf.addImage(img, x, finalY + 30, 70, 40);

        } catch (error) {
            console.log(error);
        }
        //}  

        if (this.version != 'patologia' && Historia.find(e => e.Historia.length).Historia[0].empleado) {
            pdf.text(
                Historia.find(e => e.Historia.length).Historia[0].empleado.tblConfPersonasnaturale.copnNombre1 + ' ' +
                Historia.find(e => e.Historia.length).Historia[0].empleado.tblConfPersonasnaturale.copnApellido1, x , finalY + 80);
            pdf.text(Historia.find(e => e.Historia.length).Historia[0].empleado.cargo.comaNombrelargo, x , finalY + 90);
            if (Historia.find(e => e.Historia.length).Historia[0].empleado.coprPieFirma) {
                pdf.text(Historia.find(e => e.Historia.length).Historia[0].empleado.coprPieFirma, x , finalY + 100);
            } else {
                pdf.text(Historia.find(e => e.Historia.length).Historia[0].empleado.tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto + ' Nº ' +
                Historia.find(e => e.Historia.length).Historia[0].empleado.tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, x , finalY + 100);
            }
        }
        pdf.setFontSize(7);
        //pdf.setFont("normal");
        pdf.text('IPS Tools (HIS - Hospital Information System)', x, 760 + 8);
        pdf.text('TEL: 3168775054', x, 760 + 16);
        pdf.text('®'+new Date().getFullYear()+'Powered by www.jasoftwaresas.com', x, 760 + 24);

        pdf.setFontSize(7);
        var pageCount = pdf.internal.getNumberOfPages(); // pageCount es el nº de páginas
        for (let i = 0; i < pageCount; i++) { // código que se repite para cada página (bucle)
            pdf.setPage(i);
            pdf.text(500, 760, "Página " + pdf.internal.getCurrentPageInfo().pageNumber + " de " + pageCount);
        }
        if (navigator.userAgent.indexOf('irefox') > -1) {

            pdf.output("dataurlnewwindow");
        } else {
            window.open(pdf.output('bloburl'), "_blank");
        }


        //window.open(pdf.output('bloburl'), '_blank');
    }
    getDocumento(pdf, datosPaciente, Historia, secciones, diagnosticos, listasP = [], cabeceraCup = '', firma = '') {
        let datos = null;

        if (this.version == 'patologia' && secciones.find(a => a.campos.find(b => b.foseCodigo == 'F_DEFAULT_0'))) {
            pdf.setFont('times');
            var splitTitle = pdf.splitTextToSize('N° ' + Historia.find(e => e.foseId == secciones.find(a => a.campos.find(b => b.foseCodigo == 'F_DEFAULT_0')).campos.find(a => a.foseCodigo == 'F_DEFAULT_0').foseId).ciffValor, 180);
            datos = this.getDatosPaciente(datosPaciente,'patologia',Historia , secciones);  
        } else {
            datos = this.getDatosPaciente(datosPaciente,'laboratorio');
        }
        let x = 14 + 26;
        let finalY = 5;
        if (datosPaciente.tblConfCup) {
            if (datosPaciente.tblConfCup.cocuCabecera) {
                pdf.setFontSize(10);
                let img = new Image();
                img.src = cabeceraCup;
                //var imgData = 'data:image/jpeg;base64,'+ Base64.encode(require('./assets/logo-ips-talatshi.jpg'));
                pdf.addImage(img, x - 26 + 30, finalY, 550, 80);
            } else {
                pdf = this.utils.getCabecera2(pdf, x - 26, finalY);
            }
        } else {
            pdf = this.utils.getCabecera2(pdf, x - 26, finalY);
        }
        pdf.line(x, finalY + 90, 572, finalY + 90);

        pdf.setFont(undefined, "bold");

        if (this.version == 'patologia' && secciones.find(a => a.campos.find(b => b.foseCodigo == 'F_DEFAULT_0'))) {
            pdf.setFontSize(12);
            pdf.setFont('times');
            pdf.text('INFORME DE PATOLOGIA ' + splitTitle, x + 260, finalY + 100, 'center');
            pdf.line(x, finalY + 112, 572, finalY + 112);

        } else {
            if (Historia[0].cifaId) {
                let splitTitle = pdf.splitTextToSize('INFORME DE PROCEDIMIENTO: ' + datosPaciente.tblConfCup.cocuNombre, 500);
                pdf.text(splitTitle, x, finalY + 100);
                pdf.line(x, finalY + 112, 572, finalY + 112);
            } else {
                let splitTitle = pdf.splitTextToSize('RESULTADO DE LABORATORIO: ' + datosPaciente.tblCitaFacturaciones[0].tblCitaOrdenes.filter(e => e.ciorId == Historia[0].ciorId)[0].tblConfCups[0].cocuNombre, 500);
                pdf.text(splitTitle, x, finalY + 100);
                pdf.line(x, finalY + 112, 572, finalY + 112);
            }
        }

        pdf.autoTable({
            theme: 'plain' ,
            styles: (this.version == 'patologia' && secciones.find(a => a.campos.find(b => b.foseCodigo == 'F_DEFAULT_0'))) ? { cellPadding: 0.2, font: 'times', fontSize: 12 } : { cellPadding: 0.2, fontSize: 6 },
            ...(this.version == 'patologia' && secciones.find(a => a.campos.find(b => b.foseCodigo == 'F_DEFAULT_0'))) ? {
                tableLineColor: [0, 0, 0],
                tableLineWidth: 0.1
            } : {},
            startY: finalY + 117,
            didParseCell: (data) => {
                data.cell.styles.textColor = [0, 0, 0]
                if (this.version == 'patologia') {
                    data.cell.styles.fontStyle = 'bold'
                    data.cell.styles.textColor = [0, 0, 0]
                }
               
                if (!(data.row.index % 2) && this.version != 'patologia') {
                    data.cell.styles.fontStyle = 'bold'
                }
            },

            showHead: 'firstPage',
            head: [
                [{
                    content: 'DATOS DEL PACIENTE',
                    colSpan: (this.version == 'patologia' && secciones.find(a => a.campos.find(b => b.foseCodigo == 'F_DEFAULT_0'))) ? 2 : 4,
                    styles: { ...(this.version == 'patologia' && secciones.find(a => a.campos.find(b => b.foseCodigo == 'F_DEFAULT_0'))) ? { fillColor: [255, 255, 255] } : {lineColor: [0, 0, 0],lineWidth: 0.2 }, halign: 'center', valing: 'center' },

                }]
            ],
            body: datos
        });

        if (this.version == 'patologia' && secciones.find(a => a.campos.find(b => b.foseCodigo == 'F_DEFAULT_0'))) {
            secciones.forEach((seccion) => {
                let head = [
                    [{
                        content: (this.version == 'patologia') ? '' : seccion.foseNombre,
                        //colSpan: (seccion.foseHistorico) ? 3 : 2,
                        styles: { halign: 'center', valing: 'center' },
                    }]
                ];
                let tabla = [];
                if (seccion.campos.length > 0) {
                    seccion.campos.forEach((campo) => {
                        if (Historia.find(e => e.foseId == campo.foseId) != undefined) {
                            if ((this.version != 'patologia' && campo.foseCodigo != 'F_CITO_S6_13') || secciones.find(a => a.campos.find(b => b.foseCodigo == 'F_DEFAULT_0')) == undefined || (this.version == 'patologia' && campo.foseCodigo == 'F_DEFAULT_9' && (Historia.find(e => e.foseId == campo.foseId) && Historia.find(e => e.foseId == campo.foseId).ciffValor != 'NO APLICA'))
                                || (this.version == 'patologia' && campo.foseCodigo != 'F_DEFAULT_4' && campo.foseCodigo != 'F_DEFAULT_5' && campo.foseCodigo != 'F_DEFAULT_0' && campo.foseCodigo != 'F_DEFAULT_6' && campo.foseCodigo != 'F_DEFAULT_7' && campo.foseCodigo != 'F_DEFAULT_8' && campo.foseCodigo != 'F_DEFAULT_9' && campo.foseCodigo != 'F_DEFAULT_10' && campo.foseCodigo != 'F_DEFAULT_11')
                            ) {
                                let item = Historia.find(e => e.foseId == campo.foseId);
                                tabla.push([{ content: campo.foseNombre, styles: { halign: 'left', valing: 'center', fontStyle: 'bold' } }]);
                                let val = (item.ciffValor != null) ? item.ciffValor : '';


                                if (campo.tipodato.comaNombrecorto == 'SI/NO' || campo.tipodato.comaNombrecorto == 'LISTA') {
                                    tabla.push([`${this.utils.getValues(campo.foseValores, item.ciffValor)} (${item.ciffValor})`]);
                                } else {
                                    tabla.push([{ content: val, styles: (this.version != 'patologia' || (this.version == 'patologia' && campo.foseCodigo == 'F_DEFAULT_3')) ? {} : {} }]);
                                }


                            }
                        }
                    });
                }
                finalY = pdf.previousAutoTable.finalY;
                pdf.autoTable({
                    theme: 'plain',
                    didParseCell: (table) => {

                        if (table.section === 'head' && this.version == 'patologia') {
                            table.cell.styles.fillColor = '#fff';
                            table.cell.styles.textColor = '#3b83bd';
                        }
                    },

                    styles: (this.version == 'patologia') ? { cellPadding: 1.5, font: 'times', fontSize: 12, textColor: [0, 0, 0], /*overflow: 'linebreak' */ } : { cellPadding: 0.5, fontSize: 8 },
                    startY: finalY + 5, head: head, body: tabla, showHead: 'firstPage', pageBreak: 'auto'
                });
                finalY = pdf.previousAutoTable.finalY;
                tabla = [];

            });
        } else {
            secciones.forEach((seccion) => {
                let head = [
                    [{
                        content: seccion.foseNombre,
                        colSpan: 12,
                        styles: { halign: 'center', valing: 'center' ,lineColor: [0, 0, 0],lineWidth: 0.2 },
                    }]
                ];
                let tabla = [];
                let row = [];
                let rowt = [];

                let ultimo = 0;

                if (seccion.campos.length > 0) {
                    let totalLength = seccion.campos.length;
                    let Tindex = 0;
                    if ((seccion.campos.find(b => b.tblConfFormularioseccione.foseCodigo == 'F_CITO_S6_13') != undefined)
                        ) {
                            totalLength = totalLength - 1;
                        }
                    seccion.campos.forEach((campo) => {
                        if ((campo.tblConfFormularioseccione.foseCodigo != 'F_CITO_S6_13')&& campo.tblConfFormularioseccione.tipo.comaNombrecorto=='CAMPO') {
                            if (row.length > 0 && rowt.length > 0 && (parseInt(campo.tblConfFormularioseccione.foseCol) + ultimo) > 11) {

                                rowt[(rowt.length - 1)].colSpan = rowt[(rowt.length - 1)].colSpan + (12 - ultimo);
                                row[(row.length - 1)].colSpan = row[(row.length - 1)].colSpan + (12 - ultimo);
                                tabla.push(rowt);
                                tabla.push(row);
                                rowt = null;
                                rowt = [];
                                row = null;
                                row = [];
                                ultimo = 0;
                                
                            }
                            ultimo = parseInt(campo.tblConfFormularioseccione.foseCol) + ultimo;
                            let val = '';
                            let valor = campo.tblConfFormularioseccione.foseNombre + ': ';
                            pdf.splitTextToSize(valor, parseInt(campo.tblConfFormularioseccione.foseCol) * 13).forEach(element => val = val + element + '\n');
                            rowt.push({ content: valor, colSpan: parseInt(campo.tblConfFormularioseccione.foseCol), styles: { fontStyle: 'bold' } });

                            let val1 = '';
                            valor = ((campo.tblConfFormularioseccione.tipodato.comaNombrecorto == 'LISTA') ? `${this.utils.getValues(campo.tblConfFormularioseccione.foseValores, campo.ciffValor)}` : (campo.ciffValor ? campo.ciffValor : ''));
                            pdf.splitTextToSize(valor, parseInt(campo.tblConfFormularioseccione.foseCol) * 14).forEach(element => val1 = val1 + element + '\n');
                            row.push({ content: valor, colSpan: parseInt(campo.tblConfFormularioseccione.foseCol), styles: {} });

                            if (ultimo== 12 || totalLength >= 4) {
                                if (ultimo == 12) {
                                    ultimo = 0;
                                    tabla.push(rowt);
                                    tabla.push(row);
                                    rowt = null;
                                    rowt = [];
                                    row = null;
                                    row = [];
                                } else {
                                    if ((Tindex + 1) == totalLength) {
                                        ultimo = 0;
                                        rowt[(rowt.length - 1)].colSpan = rowt[(rowt.length - 1)].colSpan + (12 - rowt[(rowt.length - 1)].colSpan);
                                        row[(row.length - 1)].colSpan = row[(row.length - 1)].colSpan + (12 - row[(row.length - 1)].colSpan);
                                        tabla.push(rowt);
                                        tabla.push(row);
                                        rowt = null;
                                        rowt = [];
                                        row = null;
                                        row = [];
                                    }
                                }

                            } else {
                                if ((Tindex + 1) == totalLength) {
                                    ultimo = 0;
                                    tabla.push(rowt);
                                    tabla.push(row);
                                    rowt = null;
                                    rowt = [];
                                    row = null;
                                    row = [];
                                }
                            }
                            if (((Tindex + 1) % 10) == 0) {
                                finalY = pdf.previousAutoTable.finalY;

                                pdf.autoTable({
                                    theme:'plain',
                                    styles: { cellPadding: 0.2, fontSize: 6, textColor: [0, 0, 0], overflow: 'linebreak' },
                                     startY: (((Tindex + 1) - 10) == 0) ? (finalY + 5) : finalY, head: (((Tindex + 1) - 10) == 0) ? head : [], body: tabla, showHead: 'firstPage', pageBreak: 'auto'
                                });
                                finalY = pdf.previousAutoTable.finalY;
                                tabla = [];
                            } else if ((Tindex + 1) == totalLength && totalLength < 10) {

                                    finalY = pdf.previousAutoTable.finalY;
                                    pdf.autoTable({
                                        theme:'plain',
                                        styles: { cellPadding: 0.2, fontSize: 6, textColor: [0, 0, 0], overflow: 'linebreak' },
                                         startY: finalY + 5, head: head, body: tabla, showHead: 'firstPage', pageBreak: 'auto'
                                    });
                                    finalY = pdf.previousAutoTable.finalY;
                                    tabla = [];
                            } else  if ((Tindex + 1) == totalLength && tabla.length > 0) {

                                    finalY = pdf.previousAutoTable.finalY;
                                    pdf.autoTable({
                                        theme:'plain',
                                        styles: { cellPadding: 0.2, fontSize: 6, textColor: [0, 0, 0], overflow: 'linebreak' },
                                        startY: finalY, head: [], body: tabla, showHead: 'firstPage', pageBreak: 'auto'
                                    });
                                    finalY = pdf.previousAutoTable.finalY;
                                    tabla = [];
                                }
                                Tindex = Tindex +1;
                            }else if(campo.tblConfFormularioseccione.tipo.comaNombrecorto=='SECCION' && campo.ciffValor != null){
                                let jsonciffValor = JSON.parse(campo.ciffValor);
                                let headJson = [jsonciffValor[0].map(item => item.label)];
                                let dataJson = jsonciffValor.map(item => item.map(items=> items.value));
                                finalY = pdf.previousAutoTable.finalY;
                                    pdf.autoTable({
                                        theme:'plain',
                                        styles: { cellPadding: 0.2, fontSize: 6, textColor: [0, 0, 0], overflow: 'linebreak' },
                                         startY: finalY+5, head: headJson, body: dataJson, showHead: 'firstPage', pageBreak: 'auto'
                                    });
                                    finalY = pdf.previousAutoTable.finalY;
                            }

                            
                    });
                }
            });

        }

        finalY = pdf.previousAutoTable.finalY;
        if (this.version != 'patologia') {
            let d = this.getDiagnosticos(pdf, diagnosticos, true, datosPaciente, finalY);
            pdf = d.pdf;
            finalY = d.finalY;
        }

        pdf.setFontSize(9);
        pdf.setFont(undefined, "bold");

        if (this.version != 'patologia' || secciones.find(a => a.campos.find(b => b.foseCodigo == 'F_DEFAULT_0')) == undefined) {
            if (secciones.find(e => e.campos.find(a => a.tblConfFormularioseccione.foseCodigo == 'F_CITO_S6_13'))) {
                try {
                    pdf.setDrawColor(0, 0, 0);
                    let valor = Historia.find(e => e.foseId == secciones.find(e => e.campos.find(a => a.tblConfFormularioseccione.foseCodigo == 'F_CITO_S6_13')).campos.find(a => a.tblConfFormularioseccione.foseCodigo == 'F_CITO_S6_13').foseId);
                    let l = listasP.find(e => e.foseId == valor.foseId).lista;
                    if (l.find(a => a.values == valor.ciffValor)) {
                        pdf.line(x, finalY + 70, x + 100, finalY + 70);
                        pdf.text(l.find(a => a.values == valor.ciffValor).texts.split(' - ')[0] + " - " + l.find(a => a.values == valor.ciffValor).texts.split(' - ')[2], x, finalY + 80);
                        if(l.find(a => a.values == valor.ciffValor).texts.split(' - ')[3]){
                            pdf.text(l.find(a => a.values == valor.ciffValor).texts.split(' - ')[3], x, finalY + 90);
                        }else{
                            pdf.text('C.C: ' + valor.ciffValor, x, finalY + 90);
                        }
                        
                        let img = new Image();
                        img.src = firma;

                        pdf.line(x + 280, finalY + 70, x + 380, finalY + 70);
                        pdf.text("PATOLOGO(A)", x + 300, finalY + 80);
                        pdf.addImage(img, x, finalY + 30, 70, 40);
                    }

                } catch (error) {
                    console.log(error);
                }
            } else if(Historia[0].empleado) {
                if (Historia[0].empleado && Historia[0].empleado.tblConfPersonasnaturale.tblConfPersona.copeFirma != null) {
                    try {
                        let img = new Image();
                        img.src = firma;
                        pdf.addImage(img, x, finalY + 30, 70, 40);

                    } catch (error) {
                        console.log(error);
                    }
                }
                pdf.text(
                   Historia[0].empleado.tblConfPersonasnaturale.copnNombre1 + ' ' +
                   Historia[0].empleado.tblConfPersonasnaturale.copnApellido1, x , finalY + 80);
                pdf.text(Historia[0].empleado.cargo.comaNombrelargo, x , finalY + 90);
                if (Historia[0].empleado.coprPieFirma) {
                    pdf.text(Historia[0].empleado.coprPieFirma, x , finalY + 100);
                } else {
                    pdf.text(Historia[0].empleado.tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto + ' Nº ' +
                   Historia[0].empleado.tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, x , finalY + 100);
                }

            }

        } else {
            if (this.version == 'patologia' && this.entidad == 'patologia') {
                try {
                    let valor = Historia.find(e => e.foseId == secciones.find(e => e.campos.find(a => a.foseCodigo == 'F_DEFAULT_6')).campos.find(a => a.foseCodigo == 'F_DEFAULT_6').foseId);
                    let lista = secciones.find(e => e.campos.find(a => a.foseCodigo == 'F_DEFAULT_6')).campos.find(a => a.foseCodigo == 'F_DEFAULT_6').foseValores;
                    pdf.text('DR. ' + this.utils.getValues(lista, valor.ciffValor), x, finalY + 80);
                    pdf.text('R.M: ' + valor.ciffValor.split(',')[1], x, finalY + 90);
                    let img = new Image();
                    img.src = firma;
                    pdf.addImage(img, x, finalY + 30, 70, 40);

                } catch (error) {
                    console.log(error);
                }
            }

            if (this.version == 'patologia' && this.entidad == 'patologia2') {
                try {
                    let valor = Historia.find(e => e.foseId == secciones.find(e => e.campos.find(a => a.foseCodigo == 'F_DEFAULT_6')).campos.find(a => a.foseCodigo == 'F_DEFAULT_6').foseId);
                    let lista = secciones.find(e => e.campos.find(a => a.foseCodigo == 'F_DEFAULT_6')).campos.find(a => a.foseCodigo == 'F_DEFAULT_6').foseValores;
                    pdf.text(this.utils.getValues(lista, valor.ciffValor), x, finalY + 80);
                    pdf.text('MEDICO PATOLOGA', x, finalY + 90);
                    pdf.text('R.M: ' + valor.ciffValor.split(',')[1], x, finalY + 100);
                    let img = new Image();
                    img.src = firma;
                    pdf.addImage(img, x, finalY + 30, 70, 40);

                } catch (error) {
                    console.log(error);
                }
            }
        }
        if (this.version == 'patologia' || secciones.find(a => a.campos.find(b => b.foseCodigo == 'F_DEFAULT_0'))) {
            pdf.setTextColor(92, 179, 255);
        }
        pdf.setFontSize(7);
        //pdf.setFont("normal");
        pdf.text('IPS Tools (HIS - Hospital Information System)', x, 760 + 8);
        pdf.text('TEL: 3168775054', x, 760 + 16);
        pdf.text('®'+new Date().getFullYear()+'Powered by www.jasoftwaresas.com', x, 760 + 24);
        if (this.version == 'patologia' && this.entidad == 'patologia') {
            pdf.text('CR 11 No. 14-52 BARRIO LIBERTADOR; TEL: 7275288', x + 250, 760 + 8, 'center');
            pdf.text('EMAIL: sociedaddepatologia@outlook.com', x + 250, 760 + 16, 'center');
            pdf.text('RIOHACHA - LA GUAJIRA', x + 250, 760 + 24, 'center');
        }
        pdf.setFontSize(7);
        pdf.setTextColor(0, 0, 0);
        var pageCount = pdf.internal.getNumberOfPages(); // pageCount es el nº de páginas
        for (let i = 0; i < pageCount; i++) { // código que se repite para cada página (bucle)
            pdf.setPage(i);
            pdf.text(500, 760, "Página " + pdf.internal.getCurrentPageInfo().pageNumber + " de " + pageCount);
        }
        if (navigator.userAgent.indexOf('irefox') > -1) {

            pdf.output("dataurlnewwindow");
        } else {
            window.open(pdf.output('bloburl'), "_blank");
        }


        //window.open(pdf.output('bloburl'), '_blank');
    }
    setFirmas(datosPaciente, pdf , x , finalY) {
        if (
            datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeFirmaPaciente != null &&
            datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeFirmaPaciente != ''
        ) {
            try {
                let paciente = datosPaciente.tblConfPersonaspacientes[0].tblConfPersonasnaturale;
                let firmaPaciente = paciente.tblConfPersona?.copeFirmaPaciente || null;
                let nombrePaciente = `${paciente.copnNombre1} ${paciente.copnNombre2 || ''} ${paciente.copnApellido1} ${paciente.copnApellido2 || ''}`.trim();
                let tipoIdentificacion = paciente.tblConfPersona?.copeIdentificacion || "No disponible";
              
                if (firmaPaciente) {
                  let imgPaciente = new Image();
                  imgPaciente.src = firmaPaciente;
                  pdf.addImage(imgPaciente, x + 80, finalY + 10, 50, 25);
                }
              
                pdf.setFont("helvetica", "bold");
                pdf.text("PACIENTE:", x + 80, finalY + 29);
                pdf.setFont("helvetica", "normal");
                pdf.text(nombrePaciente, x + 95, finalY + 29);
                 
                pdf.setFont("helvetica", "bold");
                pdf.text("NÚMERO DE IDENTIFICACIÓN:", x + 80, finalY + 34);
                pdf.setFont("helvetica", "normal");
                pdf.text(tipoIdentificacion, x + 117, finalY + 34);
              } catch (error) {
                console.log("Error al cargar los datos del paciente:", error);
              }
        }
        if (
            datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != null &&
            datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma != ''
        ) {
            try {
                 
                let imgEmpleado = new Image();
                imgEmpleado.src = datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma;
                
                pdf.addImage(imgEmpleado, x , finalY + 5, 35, 20); // Ajusta la posición según necesidad
            } catch (error) {
                console.log(error);
            }
        }
        pdf.text(
            datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + ' ' +
            datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1, x, finalY + 30);
        pdf.text(datosPaciente.tblConfPersonasempleados[0].cargo.comaNombrelargo, x, finalY + 34);
        if (datosPaciente.tblConfPersonasempleados[0].coprPieFirma) {
            pdf.text(datosPaciente.tblConfPersonasempleados[0].coprPieFirma, x, finalY + 38);
        } else {
            pdf.text(datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto + ' Nº ' +
                datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion, x, finalY + 38);
        }
    }
}